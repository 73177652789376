import Service from '@/_helpers/Service'

const ParseService = new Service({
    method: 'POST',
    url: '/api/parsing/',
})

export const parsingRequest = (token: string, username: string, social: string) => {
    return ParseService.call(
        {
            data: [{ blogger_url: username, for: social }],
        },
        token
    )
}
