import { AutocompleteResult, AutocompleteResultVk } from '@/Reponses'
import {
    searchInstagramBlogger,
    searchTelegramChannel,
    searchTikTokBlogger,
    searchTwitchChannel,
    searchVkBlogger,
    searchYouTubeChannel,
} from '@/Services/Search'
import useToken from '@/_helpers/Hooks/useToken'
import { useQuery } from '@apollo/client'
import { useState, useEffect, useMemo } from 'react'
import { BehaviorSubject, from, of } from 'rxjs'
import { debounceTime, distinctUntilChanged, switchMap, skipWhile, catchError, tap, map } from 'rxjs/operators'
import { parseUrl } from './parseUrl'

import { GET_UNLOCKED_CHANNELS } from '@/pages/Lists/model/graphQL'
import { IUnlockedChannels } from '@/pages/Lists/model/types'
import { Platform } from '@/pages/Reports/types'

interface IUseSearch {
    yt?: boolean
    twitch?: boolean
    vk?: boolean
    tg?: boolean
    tiktok?: boolean
    instagram?: boolean
}

export const useSearch = ({ yt, twitch, vk, tg, tiktok, instagram }: IUseSearch) => {
    const [search, setSearch] = useState('')
    const [instResults, setInstResults] = useState<AutocompleteResult[]>(() => [])
    const [combinedResults, setCombinedResults] = useState<AutocompleteResult[]>(() => [])
    const [current, setCurrent] = useState('')
    const [error, setError] = useState(false)
    const [isInstagramLoading, setIsInstagramLoading] = useState(false)

    const [titleQueryData, setTitleQueryData] = useState<AutocompleteResult[]>([])
    const [titleQueryLoading, setTitleQueryLoading] = useState(false)
    const [titleQueryError, setTitleQueryError] = useState(undefined)

    const [twitchQueryData, setTwitchQueryData] = useState<AutocompleteResult[]>([])
    const [twitchQueryLoading, setTwitchQueryLoading] = useState(false)
    const [twitchQueryError, setTwitchQueryError] = useState(undefined)

    const [vkQueryData, setVkQueryData] = useState<AutocompleteResultVk[]>([])
    const [vkQueryLoading, setVkQueryLoading] = useState(false)
    const [vkQueryError, setVkQueryError] = useState(undefined)

    const [tgQueryData, setTgQueryData] = useState<AutocompleteResult[]>([])
    const [tgQueryLoading, setTgQueryLoading] = useState(false)
    const [tgQueryError, setTgQueryError] = useState(undefined)

    const [tiktokQueryData, setTiktokQueryData] = useState<AutocompleteResult[]>([])
    const [tiktokQueryLoading, setTiktokQueryLoading] = useState(false)
    const [tiktokQueryError, setTiktokQueryError] = useState(undefined)
    //eslint-disable-next-line
    const unlockedChannelsIds = useQuery<IUnlockedChannels>(GET_UNLOCKED_CHANNELS, {
        skip: !yt,
        fetchPolicy: 'cache-first',
    })

    const clearSearch = () => setSearch('')

    const subj = useMemo(() => {
        return new BehaviorSubject<string>('')
    }, [])

    const token = useToken()

    useEffect(() => {
        subj.next(search)
    }, [subj, search])

    useEffect(() => {
        setError(false)
    }, [search])

    useEffect(() => {
        if (!token || !instagram) return
        const sub = subj
            .pipe(
                debounceTime(500),
                skipWhile((val) => !val),
                distinctUntilChanged(),
                map(parseUrl),
                tap((value) => setCurrent(value)),
                switchMap((search) => {
                    setIsInstagramLoading(true)

                    return from(searchInstagramBlogger(token, search)).pipe(
                        catchError(() => {
                            setError(true)
                            setIsInstagramLoading(false)
                            return of([] as AutocompleteResult[])
                        }),
                        tap(() => setIsInstagramLoading(false))
                    )
                })
            )
            .subscribe(setInstResults)
        return () => sub.unsubscribe()
    }, [subj, token, instagram])

    useEffect(() => {
        if (!yt || search.length === 0) return
        const delayedSearch = setTimeout(async () => {
            try {
                setTitleQueryError(undefined)
                setTitleQueryLoading(true)
                const res = await searchYouTubeChannel(token || '', search)
                setTitleQueryData(res)
            } catch (e) {
                console.error(e)
                setTitleQueryError(e)
            } finally {
                setTitleQueryLoading(false)
            }
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [yt, search, token])

    useEffect(() => {
        if (!twitch || search.length === 0) return

        const delayedSearch = setTimeout(async () => {
            try {
                setTwitchQueryError(undefined)
                setTwitchQueryLoading(true)
                const res = await searchTwitchChannel(token || '', search)
                setTwitchQueryData(res)
            } catch (e) {
                console.error(e)
                setTwitchQueryError(e)
            } finally {
                setTwitchQueryLoading(false)
            }
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [twitch, search, token])

    useEffect(() => {
        if (!vk || search.length === 0) return

        const delayedSearch = setTimeout(async () => {
            try {
                setVkQueryError(undefined)
                setVkQueryLoading(true)
                const res = await searchVkBlogger(token || '', search)
                setVkQueryData(res)
            } catch (e) {
                console.error(e)
                setVkQueryError(e)
            } finally {
                setVkQueryLoading(false)
            }
        }, 500)
        return () => {
            clearTimeout(delayedSearch)
        }
    }, [vk, search, token])

    useEffect(() => {
        if (!tg || search.length === 0) return

        const delayedSearch = setTimeout(async () => {
            try {
                setTgQueryError(undefined)
                setTgQueryLoading(true)
                const res = await searchTelegramChannel(token || '', search)
                setTgQueryData(res)
            } catch (e) {
                console.error(e)
                setTgQueryError(e)
            } finally {
                setTgQueryLoading(false)
            }
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [tg, search, token])

    useEffect(() => {
        if (!tiktok || search.length === 0) return

        const delayedSearch = setTimeout(async () => {
            try {
                setTiktokQueryError(undefined)
                setTiktokQueryLoading(true)
                const res = await searchTikTokBlogger(token || '', search)
                setTiktokQueryData(res)
            } catch (e) {
                console.error(e)
                setTiktokQueryError(e)
            } finally {
                setTiktokQueryLoading(false)
            }
        }, 500)

        return () => {
            clearTimeout(delayedSearch)
        }
    }, [tiktok, search, token])

    const twitchResults: AutocompleteResult[] = useMemo(() => {
        if (!twitch) return []

        const isReady = !twitchQueryError && !twitchQueryLoading && twitchQueryData

        return isReady && twitchQueryData
            ? twitchQueryData.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.external_id,
                  followers: channel.followers,
                  fullname: channel.fullname,
                  is_promo: false,
                  unlocked: channel.unlocked || false,
                  type: Platform.Twitch,
              }))
            : []
    }, [twitchQueryData, twitchQueryLoading, twitchQueryError, twitch])

    const vkResults: AutocompleteResult[] = useMemo(() => {
        if (!vk) return []

        const isReady = !vkQueryError && !vkQueryLoading && vkQueryData && vkQueryData

        return isReady && vkQueryData && vkQueryData
            ? vkQueryData.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.external_id,
                  followers: channel.followers,
                  fullname: channel.fullname,
                  is_promo: false,
                  unlocked: channel.unlocked || false,
                  type: Platform.Vk,
                  vkAccountType: channel?.account_type || 'group',
              }))
            : []
    }, [vkQueryData, vkQueryLoading, vkQueryError, vk])

    const tgResults: AutocompleteResult[] = useMemo(() => {
        if (!tg) return []

        const isReady = !tgQueryError && !tgQueryLoading && tgQueryData

        return isReady && tgQueryData && tgQueryData
            ? tgQueryData.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.external_id,
                  followers: channel.followers,
                  fullname: channel.fullname,
                  is_promo: false,
                  unlocked: channel.unlocked || false,
                  type: Platform.Tg,
              }))
            : []
    }, [tgQueryData, tgQueryLoading, tgQueryError, tg])

    const tiktokResults: AutocompleteResult[] = useMemo(() => {
        if (!tiktok) return []

        const isReady = !tiktokQueryError && !tiktokQueryLoading && tiktokQueryData

        return isReady && tiktokQueryData
            ? tiktokQueryData.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.external_id,
                  followers: channel.followers,
                  fullname: channel.fullname,
                  unlocked: channel.unlocked || false,
                  type: Platform.TikTok,
                  is_promo: false,
              }))
            : []
    }, [tiktokQueryData, tiktokQueryLoading, tiktokQueryError, tiktok])

    const ytTitleResults: AutocompleteResult[] = useMemo(() => {
        if (!yt) return []

        const isReady = !titleQueryError && !titleQueryLoading && titleQueryData

        return isReady && titleQueryData
            ? titleQueryData.map((channel) => ({
                  username: channel.username,
                  avatar: channel.avatar,
                  external_id: channel.external_id,
                  followers: channel.followers,
                  fullname: channel.fullname,
                  is_promo: false,
                  unlocked: channel.unlocked || false,
                  type: Platform.YouTube,
              }))
            : []
    }, [titleQueryData, titleQueryLoading, titleQueryError, yt])

    useEffect(() => {
        let newResults: AutocompleteResult[] = []

        if (search.length === 0) {
            setCombinedResults([])
            return
        }

        if (instagram) {
            newResults = instResults.map((res) => ({ ...res, type: Platform.Instagram }))
        }
        if (yt) {
            newResults = newResults.concat(ytTitleResults)
        }
        if (twitch) {
            newResults = newResults.concat(twitchResults)
        }
        if (vk) {
            newResults = newResults.concat(vkResults)
        }
        if (tg) {
            newResults = newResults.concat(tgResults)
        }
        if (tiktok) {
            newResults = newResults.concat(tiktokResults)
        }

        setCombinedResults(newResults)
    }, [
        instResults,
        ytTitleResults,
        twitchResults,
        vkResults,
        tgResults,
        tiktokResults,
        instagram,
        yt,
        twitch,
        vk,
        tg,
        tiktok,
        search,
    ])

    const isLoading = useMemo(() => {
        return (
            titleQueryLoading ||
            isInstagramLoading ||
            twitchQueryLoading ||
            vkQueryLoading ||
            tgQueryLoading ||
            tiktokQueryLoading
        )
    }, [titleQueryLoading, isInstagramLoading, twitchQueryLoading, vkQueryLoading, tgQueryLoading, tiktokQueryLoading])

    return {
        search,
        setSearch,
        results: combinedResults,
        current,
        clearSearch,
        error,
        isLoading,
    }
}
