import React, { useState, useRef, useEffect } from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styles from './datePicker.module.scss'
import { ReactComponent as CalendarIcon } from '@/pages/Competitor/icons/calendar.svg'
import { ReactComponent as ClearIcon } from '@/pages/Competitor/icons/close.svg'
import { Flex } from '@/_yc'

const locales: Record<string, string> = {
    en: 'en-US',
    ru: 'ru-RU',
    fr: 'fr-FR',
    de: 'de-DE',
}

interface DatePickerProps {
    locale?: 'en' | 'ru' | 'fr' | 'de'
    placeholder?: string
    onChange?: (date: Date | null) => void
    prefix?: string
    value?: Date | null
}

const DatePicker: React.FC<DatePickerProps> = ({
    locale = 'en',
    placeholder = 'Select a date',
    onChange,
    prefix,
    value,
}) => {
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [isCalendarOpen, setIsCalendarOpen] = useState(false)
    const wrapperRef = useRef<HTMLDivElement | null>(null)

    const handleDateChange = (date: Date) => {
        setSelectedDate(date)
        setIsCalendarOpen(false)
        onChange?.(date)
    }

    const formatDate = (date: Date | null): string => {
        if (!date) return ''
        return new Intl.DateTimeFormat(locales[locale], {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        }).format(date)
    }

    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
            setIsCalendarOpen(false)
        }
    }

    const handleClear = () => {
        setSelectedDate(null)
        onChange?.(null)
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const displayDateRange = () => {
        if (selectedDate) {
            return `${prefix} ${formatDate(selectedDate)}`
        }
        return ''
    }

    return (
        <div className={styles.datePickerContainer} ref={wrapperRef}>
            <Flex className={styles.wrapper} align="center">
                <input
                    type="text"
                    value={displayDateRange()}
                    readOnly
                    placeholder={placeholder}
                    className={styles.dateInput}
                    onClick={() => setIsCalendarOpen(!isCalendarOpen)}
                />
                {!selectedDate && <CalendarIcon onClick={() => setIsCalendarOpen(!isCalendarOpen)} />}
                {/* Кнопка сброса */}
                {selectedDate && <ClearIcon className={styles.clearIcon} onClick={handleClear} />}
            </Flex>
            {isCalendarOpen && (
                <div className={styles.calendarContainer}>
                    <Calendar
                        onChange={(date: any) => handleDateChange(date)}
                        value={value || undefined}
                        locale={locale}
                    />
                </div>
            )}
        </div>
    )
}

export default DatePicker
