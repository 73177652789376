import { FiltersTimeType } from '@/pages/Competitor/module/types'

export const stats = [
    {
        label: 'competitor_analysis.total_mentions',
        value: '3,456',
        tooltip: 'competitor_analysis.total_mentions',
    },
    {
        label: 'competitor_analysis.total_views',
        value: '3.7M',
        tooltip: 'competitor_analysis.total_views',
    },
    {
        label: 'competitor_analysis.average_er',
        value: '18%',
        tooltip: 'competitor_analysis.average_er',
    },
]

export const filters = {
    keywords: {
        title: 'competitor_analysis.keywords',
        subtitle: 'competitor_analysis.expand_search',
        type: 'keywords',
    },
    excludeWords: {
        title: 'competitor_analysis.exclude_words',
        subtitle: 'competitor_analysis.exclude_unwanted_words',
        type: 'excludeWords',
    },
    hashtags: {
        title: 'competitor_analysis.hashtags',
        subtitle: 'competitor_analysis.add_hashtags',
        type: 'hashtags',
    },
}
export type Blogger = {
    id: number
    name: string
    username: string
    budget: string
    subscribers: string
    avgLikes: string
    avgComments: number
    er: string
}

export const bloggers: Blogger[] = [
    {
        id: 1,
        name: 'Samragyee rajya laxmi shah',
        username: 'srls_samragyeerlshah',
        budget: '$50 000',
        subscribers: '688.5K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '53.10%',
    },
    {
        id: 2,
        name: 'Namrata Shrestha',
        username: 'namrataashrestha',
        budget: '$50 000',
        subscribers: '648K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '49.56%',
    },
    {
        id: 3,
        name: 'Niti Shah',
        username: 'shah_niti',
        budget: '$50 000',
        subscribers: '564K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '44.25%',
    },
    {
        id: 4,
        name: 'Ayushman Desraj Shrestha',
        username: 'ayushmandsj',
        budget: '$50 000',
        subscribers: '501.4K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '17.70%',
    },
    {
        id: 5,
        name: 'Prisma Khatiwada',
        username: 'prismakhatiwada',
        budget: '$50 000',
        subscribers: '659.5K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '12.39%',
    },
    {
        id: 6,
        name: 'Crzana Subedi',
        username: 'crzana_subedi_',
        budget: '$50 000',
        subscribers: '578.6K',
        avgLikes: '1.5K',
        avgComments: 68,
        er: '12.39%',
    },
]

export const categories = [
    { label: 'competitor_analysis.mentions', value: 'posts_count' },
    { label: 'competitor_analysis.views', value: 'views_count' },
    { label: 'competitor_analysis.likes', value: 'likes_count' },
    { label: 'ads_explorer.head.comments', value: 'comments_count' },
    { label: 'competitor_analysis.budget', value: 'invested_budget' },
]

export const timeFilters: { label: string; value: FiltersTimeType }[] = [
    { label: '1 mo', value: 'last_month' },
    { label: '3 mo', value: 'last_three_months' },
    { label: '1 y', value: 'last_year' },
]
export const timeCount = {
    last_month: 1,
    last_three_months: 3,
    last_year: 12,
}
export const initialChartData = {
    series: [
        {
            name: '',
            data: [],
        },
    ],
    categories: [],
}
