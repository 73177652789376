import React from 'react'

import Icons from '@/components/Icons'
// import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getLocationsVk } from '@/Services/Search'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { detectLanguageByAlphabet } from '@/_helpers/strings/common'

const getGqlFilter = (locs: string[], percentage: number) => {
    if (!locs || locs.length === 0) return ''
    const toQueryWithOR = (filterValues: string[], percentage: number) => {
        return filterValues.length > 1
            ? `_or: [${filterValues.map(
                  (i) =>
                      `{_or: [{vk_audience_countries: {country: {_eq: "${i}"}, share: {_gte: ${
                          percentage / 100
                      }}}}, {vk_audience_cities: {city: {_eq: "${i}"}, share: {_gte: ${percentage / 100}}}}]}`
              )}]`
            : `_or: [{vk_audience_countries: {country: {_eq: "${filterValues[0]}"}, share: {_gte: ${
                  percentage / 100
              }}}}, {vk_audience_cities: {city: {_eq: "${filterValues[0]}"}, share: {_gte: ${percentage / 100}}}}]`
    }

    return toQueryWithOR(locs, percentage)
}

export const getAutienceLoc = () => {
    return new SelectorFilter({
        platform: 'infl_vk',
        title: 'filters.loc.fields.aud.label',
        autocomplete: true,
        placeholder: 'filters.loc.plh',
        icon: <Icons name="filter--geo" />,
        helper: 'filters.loc.fields.aud.text',
        space: 2,
        id: 'aud_location_vk',
        isMulti: true,
        hasPercentage: true,
        options: async (token: string, locale, query) => {
            const locs = await getLocationsVk(token, detectLanguageByAlphabet(query || ''), query)
            return locs
        },
        beforeSend: ({ value, percentage }) => {
            return {
                name: 'location.city',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value as string[], percentage),
            }
        },
    })
}
