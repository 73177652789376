import React, { FC } from 'react'
import cn from './monitoring.module.scss'
import Header from '@/pages/Competitor/components/Header'
import PageLayout from '@/Layouts/PageLayout'
import { useTranslation } from 'react-i18next'
import MentionsAnalytics from '@/pages/Competitor/Monitoring/components/MentionsAnalytics'
import TablePage from '@/pages/Competitor/Monitoring/components/BloggersList'

const Index: FC = () => {
    const { t } = useTranslation()

    return (
        <PageLayout color={'common-background'} className={cn.root} title={t('titles.t2')}>
            <div className={cn['page']}>
                <Header textBtn={'Скачать отчёт .csv'} />
                <MentionsAnalytics />
                <TablePage />
            </div>
        </PageLayout>
    )
}

export default Index
