import React, { FC, useCallback, useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PageLayout from '@/Layouts/PageLayout'
import useClass from '@/_helpers/Hooks/useClass'
import cn from './competitor.module.scss'
import Header from '@/pages/Competitor/components/Header'
import TableCompetitor from '@/pages/Competitor/components/TableCompetitor'
import SearchInput from '@/pages/Competitor/components/SearchInput'
import { Flex } from '@/_yc'
import NewSearchModal from '@/pages/Competitor/components/NewSearchModal'
import { transformData } from '@/pages/Competitor/module/utils'
import { TransformedRow } from '@/pages/Competitor/module/types'
import { createCompanyReq, deleteMonitoringCampaignReq, getCompaniesReq } from '@/pages/Competitor/module/services'
import useToken from '@/_helpers/Hooks/useToken'

const Competitor: FC = () => {
    const [isModalOpen, setModalOpen] = useState(false)
    const [tableData, setTableData] = useState<TransformedRow[]>([])
    const [loading, setLoading] = useState(false)
    const [searchBrand, setSearchBrand] = useState('')
    const [searchInn, setSearchInn] = useState('')
    const token = useToken()

    const { t } = useTranslation()
    const root = useClass(cn.root)

    const fetchData = async () => {
        setLoading(true)
        try {
            const res = await getCompaniesReq(token || '')
            const transformed = transformData(res)
            setTableData(transformed)
        } catch (error) {
            console.error('Error fetching data:', error)
        } finally {
            setLoading(false)
        }
    }

    const handleSearch = async (reportName: string, brands: { id: number; name: string }[]) => {
        try {
            await createCompanyReq(
                token || '',
                reportName,
                brands.map((b) => b.name)
            )
            await fetchData()
        } catch (error) {
            console.error('Ошибка при создании компании:', error)
        } finally {
            setModalOpen(false)
        }
    }

    useEffect(() => {
        if (token) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    const handleOpen = useCallback(() => {
        setModalOpen(true)
    }, [])

    const filteredData = useMemo(() => {
        return tableData.filter((row) => {
            const brandMatch = row.brand.toLowerCase().includes(searchBrand.toLowerCase())
            const innMatch = row.inn.toString().includes(searchInn)
            return brandMatch && innMatch
        })
    }, [tableData, searchBrand, searchInn])

    const handleDelete = async (ids: number[]) => {
        await deleteMonitoringCampaignReq(token || '', ids)
        await fetchData()
    }

    return (
        <PageLayout color={'common-background'} className={root} title={t('titles.t2')}>
            <div className={cn['page']}>
                <Header callback={handleOpen} />
                <Flex margin={'0 0 16px 0'} style={{ gap: '16px' }}>
                    <SearchInput onSearch={setSearchBrand} placeholder={t('common.search_by_name')} />
                    <SearchInput onSearch={setSearchInn} placeholder={t('competitor_analysis.search_by_inn')} />
                </Flex>
                <TableCompetitor
                    handleOpen={handleOpen}
                    loading={loading}
                    data={filteredData}
                    handleDelete={handleDelete}
                />
                <NewSearchModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} onSearch={handleSearch} />
            </div>
        </PageLayout>
    )
}

export default Competitor
