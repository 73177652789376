import { connect } from 'react-redux'
import i18n from 'i18next'
import { withProps } from 'recompose'

import { downloadPdfFile, store } from '@/_helpers/'
import notifs from '@/components/Notifier/model/actions.js'
import BloggerReport from './BloggerReport.tsx'
import { combineHOC, withPdfLoader } from '@/_helpers/HOC/'
import { service as serv } from './Model/_services.js'
import withUpdates from './withUpdates'
import withPollinger from '@/_helpers/HOC/withPollinger'
import { action } from './Model/_actions.js'

const mapStateToProps = ({
    BloggerReport: { blogger_info, switch_content },
}) => ({
    blogger: blogger_info,
    state: switch_content,
    posts:
        blogger_info.loaded && !blogger_info.error
            ? {
                  popular: blogger_info.blogger.data.topPosts.popular.values,
                  promotion:
                      blogger_info.blogger.data.topPosts.promotion.values,
              }
            : null,
})

const mapDispatch = (dispatch, ownProps) => ({
    loadReport() {
        dispatch(action.loadReport(ownProps.match.params.id))
    },
    clearReport() {
        dispatch(action.clear())
    },
    updateReport() {
        dispatch(action.updateReport(ownProps.match.params.id))
    },
})

const errorHandler = (err) => {
    console.error({ err })
    store.dispatch(notifs.error(i18n.getFixedT()('common_error'), 3000))
}

const Add = {
    CheckService: (id) =>
        new Promise((resolve, reject) => {
            serv.pollingUpdates(id)
                .then((res) => resolve(res.is_fresh))
                .catch(reject)
        }),
}

const Exp = combineHOC(
    withUpdates(),
    withProps(Add),
    withPollinger(),
    connect(mapStateToProps, mapDispatch),
    withPdfLoader(
        serv.getPdfId,
        serv.pollingService,
        downloadPdfFile,
        errorHandler
    )
)(BloggerReport)

export { Exp as BloggerReport }
