import React from 'react'

export const placeholders = {
    ru: {
        instagram: (
            <>
                <b>Что вставлять:</b> ссылки на профиль или @юзернеймы.
                <br />
                <b>Примеры:</b>
                <br />
                https://instagram.com/username
                <br />
                @username
                <br />
                <b>Совет:</b> если у вас есть столбец со ссылками Instagram, скопируйте его и вставьте сюда.
            </>
        ),
        youtube: (
            <>
                <b>Что вставлять:</b> ссылки на каналы или @юзернеймы (идентификаторы канала).
                <br />
                <b>Примеры:</b>
                <br />
                https://youtube.com/channel/UC...
                <br />
                https://youtube.com/@username
                <br />
                <b>Совет:</b> используйте подготовленный столбец с YouTube-ссылками для вставки.
            </>
        ),
        tiktok: (
            <>
                <b>Что вставлять:</b> ссылки на профиль или @юзернеймы.
                <br />
                <b>Примеры:</b>
                <br />
                https://www.tiktok.com/@username
                <br />
                @username
                <br />
                <b>Совет:</b> убедитесь, что ссылки в таблице соответствуют формату TikTok перед вставкой.
            </>
        ),
        twitch: (
            <>
                <b>Что вставлять:</b> ссылки на канал или просто имя канала.
                <br />
                <b>Примеры:</b>
                <br />
                https://twitch.tv/username
                <br />
                username
                <br />
                <b>Совет:</b> если у вас есть готовый список Twitch-ссылок, скопируйте столбец и вставьте его в поле.
            </>
        ),
        Telegram: (
            <>
                <b>Что вставлять:</b> ссылки на профиль или @юзернеймы.
                <br />
                <b>Примеры:</b>
                <br />
                https://t.me/username
                <br />
                @username
                <br />
                <b>Совет:</b> если у вас в таблице содержатся Telegram-ссылки, скопируйте их и вставьте сюда.
            </>
        ),
        vk: (
            <>
                <b>Что вставлять:</b> ссылки на профиль или имя пользователя.
                <br />
                <b>Примеры:</b>
                <br />
                https://vk.com/username
                <br />
                username
                <br />
                <b>Совет:</b> проверьте, что ссылки из таблицы корректны и соответствуют формату VK.
            </>
        ),
    },
    en: {
        instagram: (
            <>
                <b>What to Enter:</b> Profile links or @usernames.
                <br />
                <b>Examples:</b>
                <br />
                https://instagram.com/username
                <br />
                @username
                <br />
                <b>Tip:</b> If you have a column with Instagram links, copy it and paste it here.
            </>
        ),
        youtube: (
            <>
                <b>What to Enter:</b> Channel links or @usernames (channel identifiers).
                <br />
                <b>Examples:</b>
                <br />
                https://youtube.com/channel/UC...
                <br />
                https://youtube.com/@username
                <br />
                <b>Tip:</b> Use the prepared column of YouTube links from your table for a smooth paste.
            </>
        ),
        tiktok: (
            <>
                <b>What to Enter:</b> Profile links or @usernames.
                <br />
                <b>Examples:</b>
                <br />
                https://www.tiktok.com/@username
                <br />
                @username
                <br />
                <b>Tip:</b> Ensure the links in your table match the TikTok format before pasting.
            </>
        ),
        twitch: (
            <>
                <b>What to Enter:</b> Channel links or just the channel name.
                <br />
                <b>Examples:</b>
                <br />
                https://twitch.tv/username
                <br />
                username
                <br />
                <b>Tip:</b> If you have a list of Twitch links, copy the column and paste it into this field.
            </>
        ),
        Telegram: (
            <>
                <b>What to Enter:</b> Profile links or @usernames.
                <br />
                <b>Examples:</b>
                <br />
                https://t.me/username
                <br />
                @username
                <br />
                <b>Tip:</b> Copy your Telegram links from the table and paste them here.
            </>
        ),
        vk: (
            <>
                <b>What to Enter:</b> Profile links or usernames.
                <br />
                <b>Examples:</b>
                <br />
                https://vk.com/username
                <br />
                username
                <br />
                <b>Tip:</b> Double-check that the links from your table adhere to VK's format.
            </>
        ),
    },
}
