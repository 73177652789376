import React, { useMemo } from 'react'
import Chart from 'react-apexcharts'
import { ApexOptions } from 'apexcharts'
import styles from './chart.module.scss'

interface ChartProps {
    data: {
        series: { name: string; data: number[] }[]
        categories: string[]
    }
    category: string
}

const AnalyticsChart: React.FC<ChartProps> = ({ data, category }) => {
    const chartOptions = useMemo<ApexOptions>(
        () => ({
            chart: {
                toolbar: { show: false },
                zoom: { enabled: false },
                animations: { enabled: false },
                selection: { enabled: false },
                events: {
                    markerClick: (event) => {
                        event.stopPropagation()
                    },
                },
            },
            xaxis: {
                categories: data.categories,
                labels: {
                    style: {
                        fontSize: '12px',
                        colors: '#5A5F7D',
                    },
                },
                crosshairs: {
                    show: false,
                },
                tooltip: {
                    enabled: false,
                },
            },
            tooltip: {
                enabled: true,
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    return `
                <div style="
                    color: #fff;
                    padding: 8px 12px;
                    border-radius: 8px;
                    font-size: 14px;
                   
                ">
                    <div><strong>${w.globals.categoryLabels[dataPointIndex]}</strong></div>
                    <div>Всего было <strong>${series[seriesIndex][dataPointIndex]}</strong> ${category}</div>
                </div>
            `
                },
            },
            stroke: {
                width: 3,
                colors: ['#5A71FF'],
            },
            markers: {
                size: 6,
                colors: ['#5A71FF'],
                strokeColors: '#fff',
                strokeWidth: 2,
                hover: {
                    size: 6,
                },
            },
            grid: {
                borderColor: '#E0E6ED',
            },
            yaxis: {
                labels: {
                    style: {
                        colors: '#5A5F7D',
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
        }),
        [data.categories, category]
    )

    return (
        <div className={styles.chart}>
            <Chart options={chartOptions} series={data.series} type="line" height={300} />
        </div>
    )
}

export default AnalyticsChart
