import React from 'react'

import Icons from '@/components/Icons'
// import { Fetcher } from '@/_helpers/graphql/Fetcher'
import { getLocationsVk } from '@/Services/Search'
import { SelectorFilter } from '../../../../filters/SelectorFilter'
import { detectLanguageByAlphabet } from '@/_helpers/strings/common'

const getGqlFilter = (locs: string[]) => {
    if (!locs || locs.length === 0) return ''
    const toQueryWithOR = (filterValues: string[]) => {
        return filterValues.length > 1
            ? `_or: [${filterValues.map((i) => `{_or: [{country: {_eq: "${i}"}}, {city: {_eq: "${i}"}}]}`)}]`
            : `_or: [{country: {_eq: "${filterValues[0]}"}}, {city: {_eq: "${filterValues[0]}"}}]`
    }
    return toQueryWithOR(locs)
}

export const getCountryFilter = () => {
    return new SelectorFilter({
        platform: 'infl_vk',
        title: 'filters.main_locations.label',
        autocomplete: true,
        helper: 'filters.main_locations.text',
        placeholder: 'filters.main_locations.placeholder',
        icon: <Icons name="filter--geo" />,
        space: 1,
        id: 'location_vk',
        isMulti: true,
        options: async (token: string, locale, query) => {
            const locs = await getLocationsVk(token, detectLanguageByAlphabet(query || ''), query)
            return locs
        },
        beforeSend: ({ value }) => {
            return {
                name: 'location.city',
                type: 'array',
                value: value,
                gqlFilter: getGqlFilter(value as string[]),
            }
        },
    })
}
