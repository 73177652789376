import React, { FC, memo, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootStore } from '@/_helpers/StoreType'
import useToken from '@/_helpers/Hooks/useToken'
import Flex from '@/_yc/Flex'
import notif from '@/components/Notifier/model/actions.js'
import Icons from '@/components/Icons'
import Popup from '@/_yc/Popup'
import useOpenClose from '@/_helpers/useOpenClose'
import { RemoveListINST, RemoveListTwitch, RemoveListYT } from '@/components/MassAddToList/_model/actions'

import { AddToListReq } from '../../model/service'
import { ICustomListInfo } from '../../model/types'
import { updateList } from '../../model/actions'
import CreateNewList from './CreateNewList'

import cn from './AddToList.module.scss'
import { CreateListPlace } from '@/_helpers/amplitude/types'
import { track } from '@amplitude/analytics-browser'
import useWindowDimensions from '@/_helpers/Resize/useWindowDimensions'
import { MobilePopup } from '@/components/MobilePopup/MobilePopup'
import { Platform } from '@/pages/Reports/types'

interface IAddToList {
    type?: Platform
    place: CreateListPlace
    external_id?: string
    massAdd?: boolean
    massAddList?: {
        ytList: string[]
        instIdList: string[]
        instUsernameList: string[]
        twitchList: string[]
        vkList: string[]
        tgList: string[]
    }
    // Used for mobile popup
    useMobilePopup?: boolean
    isMobileOpen?: boolean
    onMobileClose?: () => void
    send_ig_bloggers_on_full_parsing_if_needed?: boolean
}
const AddToList: FC<IAddToList> = ({
    type,
    place,
    external_id,
    massAdd,
    massAddList,
    useMobilePopup,
    isMobileOpen,
    onMobileClose,
    send_ig_bloggers_on_full_parsing_if_needed,
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()
    const token = useToken()
    const dispatch = useDispatch()

    const [state, open, close] = useOpenClose()
    const customLists = useSelector((store: RootStore) => store.AudienceInsights.ListsInfo)

    const [showActiveListMobile, setShowActiveListMobile] = useState(true)

    const onActiveChange = useCallback(
        (value) => {
            setShowActiveListMobile(!value)
        },
        [setShowActiveListMobile]
    )

    const { width } = useWindowDimensions()

    const addOneItemReq = (token: string, listInfo: ICustomListInfo) => {
        AddToListReq(
            token,
            listInfo.id,
            type === Platform.Instagram && external_id ? [external_id] : undefined,
            type === Platform.YouTube && external_id ? [external_id] : undefined,
            undefined,
            type === Platform.Twitch && external_id ? [external_id] : undefined,
            undefined,
            undefined,
            type === Platform.Vk && external_id ? [external_id] : undefined,
            undefined,
            type === Platform.Tg && external_id ? [external_id] : undefined,
            undefined,
            type === Platform.TikTok && external_id ? [external_id] : undefined,
            send_ig_bloggers_on_full_parsing_if_needed
        )
            .then(() => {
                dispatch(
                    notif.success([
                        t('aud_insides.status.added_to_list_LINK'),
                        { listName: listInfo.name, listID: listInfo.id },
                    ])
                )
                dispatch(
                    updateList({
                        ...listInfo,
                        qty: 1,
                    })
                )
            })
            .catch((err: any) => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
    }

    const massAddReq = (token: string, listInfo: ICustomListInfo) => {
        let promiseAr = []
        if (massAddList?.instIdList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    massAddList?.instIdList,
                    [],
                    [],
                    [],
                    [],
                    undefined,
                    undefined,
                    undefined,
                    [],
                    undefined,
                    [],
                    send_ig_bloggers_on_full_parsing_if_needed
                )
            )
        }
        if (massAddList?.instUsernameList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    [],
                    [],
                    massAddList?.instUsernameList,
                    [],
                    [],
                    undefined,
                    undefined,
                    [],
                    [],
                    [],
                    [],
                    send_ig_bloggers_on_full_parsing_if_needed
                )
            )
        }
        if (massAddList?.ytList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, undefined, massAddList?.ytList))
        }
        if (massAddList?.twitchList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, undefined, undefined, undefined, massAddList?.twitchList))
        }
        if (massAddList?.vkList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.vkList
                )
            )
        }
        if (massAddList?.tgList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.tgList
                )
            )
        }
        if (promiseAr.length === 0) {
            return
        }

        dispatch(notif.loading(t('aud_insides.status.adding')))

        close()
        onMobileClose && onMobileClose()

        Promise.all(promiseAr)
            .then((res) => {
                dispatch(
                    notif.success([
                        t('aud_insides.status.added_to_list_LINK'),
                        { listName: listInfo.name, listID: listInfo.id },
                    ])
                )
                const countDifference = Math.max(...res.map((item) => parseInt(item.total_count))) - listInfo.qty
                dispatch(
                    updateList({
                        ...listInfo,
                        qty: countDifference,
                    })
                )
                dispatch(RemoveListYT())
                dispatch(RemoveListINST())
                dispatch(RemoveListTwitch())
            })
            .catch((e) => {
                const err = e.response?.data?.error

                if (err === 'limit is exceeded') {
                    dispatch(notif.error(t('aud_insides.status.limit_exceeded')))
                    return
                }
                dispatch(notif.error(t('aud_insides.status.error')))
            })
    }

    const addToList = (listInfo: ICustomListInfo) => {
        // dispatch(notif.loading(t('aud_insides.status.loading')))
        if (!token) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }
        if (!massAdd) {
            addOneItemReq(token, listInfo)
        } else {
            massAddReq(token, listInfo)
        }
        track(`add_to_list_${place}`)
    }

    if (width >= 744 || !useMobilePopup) {
        return (
            <>
                {!massAdd ? (
                    <div onClick={open} className={cn['button']} ref={ref}>
                        <Icons name="list-plus" className={cn['button--icon']} />
                    </div>
                ) : (
                    <>
                        <Flex onClick={open} ref={ref} className={cn['mass-add']}>
                            <Icons name="list-plus" className={cn['mass-add--icon']} />
                            <p className={cn['mass-add--text']}>{t('aud_insides.lists.addToList')}</p>
                        </Flex>
                    </>
                )}
                <Popup
                    isOpen={state}
                    onClose={close}
                    anchor={ref.current}
                    placement="bottom-end"
                    style={{ zIndex: 100 }}
                >
                    <Flex className={cn.root} column>
                        <p className={cn.header}>{t('aud_insides.lists.addToList')}</p>
                        <div className={cn.divider} />
                        <Flex className={cn['root--scrollable']} column>
                            {customLists.map((item, index) => (
                                <Flex
                                    align="center"
                                    className={cn['item-container']}
                                    onClick={() => addToList(item)}
                                    key={index}
                                >
                                    <p className={cn.item}>{item.name}</p>
                                </Flex>
                            ))}
                        </Flex>
                        <CreateNewList place={place} addToList={addToList} />
                    </Flex>
                </Popup>
            </>
        )
    }

    if (useMobilePopup) {
        return (
            <MobilePopup isOpen={isMobileOpen || false} onClose={onMobileClose || (() => {})} title="Add to list">
                {showActiveListMobile && (
                    <>
                        <Flex className={cn['root--scrollable']} column>
                            {customLists.map((item, index) => (
                                <Flex
                                    align="center"
                                    className={cn['item-container']}
                                    onClick={() => {
                                        addToList(item)
                                        onMobileClose && onMobileClose()
                                    }}
                                    key={item.id}
                                >
                                    <p className={cn.item}>{item.name}</p>
                                </Flex>
                            ))}
                        </Flex>
                        <hr className={cn.mobileDivider}></hr>
                    </>
                )}

                <CreateNewList place={place} addToList={addToList} onActiveChange={onActiveChange} />
            </MobilePopup>
        )
    }

    return <></>
}
export default memo(AddToList)
