import { Company, TransformedRow } from '@/pages/Competitor/module/types'

export const transformData = (data: Company[]): TransformedRow[] => {
    return data.map((item) => ({
        id: item.id,
        brand: item.name,
        inn: item.inn || '—',
        keywords: item.query_words.map((word) => ({ label: word })),
        postsFound: item.post_count,
        bloggers: (item.bloggers_info || []).map((blogger) => ({
            avatar: blogger.avatar,
            count: blogger.latest_metrics.followers_count,
        })),
        reportReady: item.status === 'DONE' || item.status === 'UPDATE' || item.status === 'IN_PROCESS',
    }))
}

export const modifySeriesAndCategories = (
    series: number[],
    categories: string[],
    count: number
): { series: number[]; categories: string[] } => {
    if (series.length === 1) {
        const updatedSeries = [0, ...series]

        const originalDate = new Date(categories[0])
        originalDate.setMonth(originalDate.getMonth() - count)
        const newDate = originalDate.toISOString().split('T')[0]

        const updatedCategories = [newDate, ...categories]

        return { series: updatedSeries, categories: updatedCategories }
    }

    return { series, categories }
}
export const formatDate = (date: Date | null): string | null => {
    if (!date) return null
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
}
export const formatNumber = (num: number): string => {
    if (num >= 1_000_000) return `${(num / 1_000_000).toFixed(1)}M`
    if (num >= 1_000) return `${(num / 1_000).toFixed(1)}K`
    return num.toString()
}
export const formatToDDMMYYYY = (dateString: string): string => {
    if (!dateString) return ''
    const [year, month, day] = dateString.split('-')
    return `${day}.${month}.${year}`
}
export type SortableObject<T> = T & { date: string }

export function sortByDate<T>(data: SortableObject<T>[], order: 'asc' | 'desc' = 'asc'): SortableObject<T>[] {
    return [...data].sort((a, b) => {
        const dateA = new Date(a.date).getTime()
        const dateB = new Date(b.date).getTime()

        if (order === 'asc') {
            return dateA - dateB
        } else {
            return dateB - dateA
        }
    })
}
export function formatNumberWithSpaces(number: number): string {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}
