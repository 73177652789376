import React, { FC } from 'react'
import cn from './table-header.module.scss'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'
import Icons from '@/components/Icons'
import { Flex } from '@/_yc'

interface TableHeaderProps {
    isDelete?: boolean
    handleDelete?: () => void
}

const Index: FC<TableHeaderProps> = ({ isDelete, handleDelete }) => {
    const { t } = useTranslation()

    if (isDelete) {
        return (
            <div className={cn['header-table']}>
                <Flex content="center">
                    <Icons onClick={handleDelete} name={'trash'} className={cn.trash} />
                </Flex>
            </div>
        )
    }

    return (
        <div className={cn['header-table']}>
            <Text />
            <Text fSize="10px" semibold color={'gray-new-3'}>
                {t('competitor_analysis.brand_inn')}
            </Text>
            <Text fSize="10px" semibold color={'gray-new-3'}>
                {t('competitor_analysis.hashtags')}
            </Text>
            <Text fSize="10px" semibold color={'gray-new-3'}>
                {t('competitor_analysis.posts_found')}
            </Text>
            <Text fSize="10px" semibold color={'gray-new-3'}>
                {t('competitor_analysis.bloggers_found')}
            </Text>
            <Text />
        </div>
    )
}

export default Index
