import React, { useEffect, useMemo, useState } from 'react'
import Chart from './Chart'
import Filters from './Filters'
import styles from './mentionsAnalytics.module.scss'
import Tabs from '@/pages/Competitor/Monitoring/components/MentionsAnalytics/Tabs'
import StatsCard from '@/pages/Competitor/Monitoring/components/MentionsAnalytics/StatsCard'
import FilterTabs from '@/pages/Competitor/Monitoring/components/MentionsAnalytics/FilterTabs'
import AddSearchModal from '@/pages/Competitor/components/AddSearchModal'
import { categories, filters, initialChartData, timeCount, timeFilters } from '@/pages/Competitor/module/data'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import useToken from '@/_helpers/Hooks/useToken'
import { editMonitoringCampaignReq, getMetricsReq } from '@/pages/Competitor/module/services'
import {
    CategoriesType,
    ChartDataType,
    FiltersTimeType,
    MonitoringMetrics,
    TimeSeriesData,
} from '@/pages/Competitor/module/types'
import {
    formatNumber,
    formatNumberWithSpaces,
    formatToDDMMYYYY,
    modifySeriesAndCategories,
    sortByDate,
} from '@/pages/Competitor/module/utils'
import Loader from '@/components/Loader'

type FilterType = 'keywords' | 'excludeWords' | 'hashtags'

const MentionsAnalytics: React.FC = () => {
    const [selectedCategory, setSelectedCategory] = useState<CategoriesType>({
        label: 'competitor_analysis.mentions',
        value: 'posts_count',
    })
    const [data, setData] = useState<MonitoringMetrics | null>(null)
    const [chartData, setChartData] = useState<ChartDataType>(initialChartData)

    const [isModalOpen, setModalOpen] = useState(false)
    const [filterType, setFilterType] = useState<FilterType>('keywords')
    const [timeFilterType, setTimeFilterType] = useState<FiltersTimeType>('last_month')
    const [brands, setBrands] = useState<{ [key in FilterType]: { id: number; name: string }[] }>({
        keywords: [],
        excludeWords: [],
        hashtags: [],
    })
    const [loading, setLoading] = useState(true)

    const { t } = useTranslation()
    const { id } = useParams<{ id: string }>()
    const token = useToken()
    const { pathname } = useLocation()
    const platformSlug = pathname.split('/').pop()

    const getMetricks = async () => {
        setLoading(true)
        getMetricsReq(token || '', Number(id), platformSlug || '')
            .then((res) => {
                setData(res)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        if (!token || !id || !platformSlug) return
        getMetricks()
        // eslint-disable-next-line
    }, [token, id, platformSlug])

    useEffect(() => {
        if (!data) return
        const categoryKey = selectedCategory.value as keyof TimeSeriesData
        const timeFilterKey = timeFilterType as keyof TimeSeriesData[typeof categoryKey]
        const sortedData = sortByDate(data.time_series_data[categoryKey]?.[timeFilterKey]?.data || [])

        const { series, categories } = modifySeriesAndCategories(
            sortedData.map((c) => c.count) || [],
            sortedData.map((c) => c.date) || [],
            timeCount[timeFilterType]
        )

        setChartData({
            series: [
                {
                    name: t(selectedCategory?.label),
                    data: series,
                },
            ],
            categories: categories.map((date) => formatToDDMMYYYY(date)),
        })
        setBrands({
            keywords: data.query_words.map((w, i) => ({ id: i, name: w })),
            excludeWords: data.exclusion_words.map((w, i) => ({ id: i, name: w })),
            hashtags: data.hashtags.map((w, i) => ({ id: i, name: w })),
        })
    }, [data, selectedCategory, selectedCategory.label, t, timeFilterType])

    const handleFilterChange = (category: CategoriesType, timeFilter: FiltersTimeType) => {
        setSelectedCategory(category)
        setTimeFilterType(timeFilter)
    }

    const generateStatsMessage = (category: CategoriesType, timeFilter: string): string => {
        const timeMap: Record<string, string> = {
            last_month: t('competitor_analysis.last_month'),
            last_three_months: t('competitor_analysis.last_3_months'),
            last_year: t('competitor_analysis.last_year'),
        }
        const categoryKey = category.value as keyof TimeSeriesData
        const timeFilterKey = timeFilter as keyof TimeSeriesData[typeof categoryKey]

        const count = data?.time_series_data[categoryKey]?.[timeFilterKey]?.total_count || 0
        return `${formatNumberWithSpaces(count)} ${t(category.label.toLowerCase())} ${timeMap[timeFilter] || ''}`
    }

    const updateCampaign = async (params: {
        new_keywords?: string[]
        exclusion_keywords_query?: string[]
        hashtags?: string[]
        inn?: string
    }) => {
        try {
            setLoading(true)
            await editMonitoringCampaignReq(token || '', {
                monitoring_id: Number(id),
                ...params,
            })
            getMetricks()
        } catch (error) {
            console.error('Failed to update campaign', error)
        } finally {
            setLoading(false)
        }
    }

    const addInnHandler = (text: string) => {
        updateCampaign({ inn: text })
    }

    const handleSearch = (selectedBrands: { id: number; name: string }[], filterType: FilterType) => {
        setBrands((prevBrands) => {
            const updatedBrands = {
                ...prevBrands,
                [filterType]: selectedBrands,
            }

            updateCampaign({
                new_keywords: updatedBrands.keywords.map((k) => k.name),
                hashtags: updatedBrands.hashtags.map((k) => k.name),
                exclusion_keywords_query: updatedBrands.excludeWords.map((k) => k.name),
            })

            return updatedBrands
        })
    }

    const handleFilterType = (filterType: FilterType) => {
        setFilterType(filterType)
        setModalOpen(true)
    }

    const stats = useMemo(() => {
        return [
            {
                label: 'competitor_analysis.total_mentions',
                value: formatNumber(data?.metrics_summary.total_posts || 0),
                tooltip: 'competitor_analysis.total_mentions',
            },
            {
                label: 'competitor_analysis.total_views',
                value: formatNumber(data?.metrics_summary?.total_views || 0),
                tooltip: 'competitor_analysis.total_views',
            },
            {
                label: 'competitor_analysis.average_er',
                value: ((data?.metrics_summary?.engagement_rate || 0) * 100).toFixed(1) + '%',
                tooltip: 'competitor_analysis.average_er',
            },
        ]
    }, [data])

    return (
        <div style={{ position: 'relative' }}>
            <Tabs />
            <div className={styles.analyticsContainer}>
                <StatsCard
                    title={data?.monitoring_name || ''}
                    stats={stats}
                    onActionClick={addInnHandler}
                    subtitle={t('competitor_analysis.add_inn')}
                    inn={data?.inn || ''}
                />
                <FilterTabs
                    categories={categories}
                    timeFilters={timeFilters}
                    onFilterChange={handleFilterChange}
                    getStatsMessage={generateStatsMessage}
                />
                {/* Chart */}
                <Chart data={chartData} category={t(selectedCategory?.label)} />
                {/* Filters */}
                <Filters setFilterType={handleFilterType} brands={brands} />
            </div>
            {isModalOpen && (
                <AddSearchModal
                    title={t(filters[filterType]?.title) || ''}
                    subTitle={t(filters[filterType]?.subtitle) || ''}
                    onSearch={(brands) => handleSearch(brands, filterType)}
                    onClose={() => setModalOpen(false)}
                    isOpen={isModalOpen}
                    brands={brands[filterType]}
                    loading={loading}
                />
            )}
            {loading && (
                <Loader
                    style={{
                        margin: 'auto',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            )}
        </div>
    )
}

export default MentionsAnalytics
