import React, { FC, useEffect } from 'react'

import cn from './PageLayout.module.scss'

import Header from '@/Layouts/PageLayout/header'

import Title from '@/_helpers/TitleControl'
import useClass from '@/_helpers/Hooks/useClass'
import Sidebar from './sidebar/Sidebar'

import {
    INTERSECTIONS_DEFAULT,
    LISTS,
    SEARCH,
    REPORT,
    DEMO_REPORT,
    INTERSECTIONS,
    ADS_EXPLORER,
    CAMPAIGN_MANAGER,
    OUTREACH,
    MEDIA_PLANNING,
    TARGET_AUDIENCE,
    // ADS_EXPLORER_NEW,
} from '@/routs'
import { RootStore } from '@/_helpers/StoreType'
import { useSelector } from 'react-redux'
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary'

export interface IPageLayout {
    title: string
    className?: string
    color: string
    noPadding?: boolean
}

const getLinks = (platforms: string[] | undefined) => {
    const links = [
        { title: 'top_menu.p1', to: SEARCH, icon: 'search', active: [SEARCH] },
        {
            title: 'top_menu.p2',
            to: LISTS,
            icon: 'bars',
            active: [LISTS, REPORT, DEMO_REPORT],
        },
        {
            title: 'top_menu.p6',
            to: `${CAMPAIGN_MANAGER}`,
            icon: 'campaign-manager',
            active: [CAMPAIGN_MANAGER],
            isNew: true,
        },
        {
            title: 'top_menu.p3',
            to: INTERSECTIONS_DEFAULT,
            icon: 'copy',
            active: [INTERSECTIONS],
        },
        {
            title: 'top_menu.p4',
            to: `${ADS_EXPLORER}/instagram`,
            icon: 'ads-explorer',
            active: [ADS_EXPLORER],
        },
        // {
        //     title: 'top_menu.p4',
        //     to: ADS_EXPLORER_NEW,
        //     icon: 'ads-explorer',
        //     active: [ADS_EXPLORER_NEW],
        // },
        {
            title: 'top_menu.p7',
            to: `${OUTREACH}`,
            icon: 'outreach',
            active: [`${OUTREACH}`],
        },
        {
            title: 'top_menu.p8',
            to: `${MEDIA_PLANNING}`,
            icon: 'media-planner',
            active: [MEDIA_PLANNING],
        },
    ]

    if (platforms?.includes('audience')) {
        links.splice(5, 0, {
            title: 'target_audience.target_audience',
            to: `${TARGET_AUDIENCE}`,
            icon: 'audience',
            active: [TARGET_AUDIENCE],
        })
    }

    return links
}

const PageLayout: FC<IPageLayout> = (props) => {
    const platforms = useSelector((store: RootStore) => store.authentication.user?.platforms)
    useEffect(() => {
        new Title().label = props.title
    }, [props.title])

    const root = useClass(cn.root, props.className)

    const rootWithoutPadding = useClass(cn['root-no-padding'], props.className)

    const links = getLinks(platforms)

    return (
        <div className={props.noPadding ? rootWithoutPadding : root} id="page-layout-yolo">
            <Header color={props.color} links={links} />
            <div style={{ backgroundColor: '#F1F3F8' }}>
                <Sidebar links={links} />
                <ErrorBoundary>
                    <div className={cn.children}>{props.children}</div>
                </ErrorBoundary>
            </div>
        </div>
    )
}

export default PageLayout
