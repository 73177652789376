import React from 'react'
import styles from './table.module.scss'
import CustomCheckbox from '@/components/CustomCheckbox'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import Tooltip from '@/components/Tooltip'
import { useTranslation } from 'react-i18next'
import { PostInfo } from '@/pages/Competitor/module/types'
import Loader from '@/components/Loader'

interface TableProps {
    data: PostInfo[]
    loading: boolean
}

const Table: React.FC<TableProps> = ({ data, loading }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.tableContainer}>
            <div className={styles.headerRow}>
                <Flex />
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('competitor_analysis.name_date_channel')}
                </Text>
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('competitor_analysis.approx_budget')}
                </Text>
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('competitor_analysis.views')}
                </Text>
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('competitor_analysis.likes')}
                </Text>
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('competitor_analysis.comments')}
                </Text>
                <Text fSize="10px" color={'gray-new-3'} semibold>
                    {t('ER')}
                </Text>
            </div>
            {data.map((row, index) => (
                <Flex key={row.post_id} className={styles.row}>
                    <Flex className={styles.cell} align={'center'}>
                        <Tooltip title={t('Исключить из отчета')} placement={'top'}>
                            <Flex>
                                <CustomCheckbox />
                            </Flex>
                        </Tooltip>
                        <Flex margin="0 0 0 20px">
                            <img className={styles.ava} src={row.blogger_avatar} alt={row.blogger_username} />
                        </Flex>
                    </Flex>
                    <Flex className={styles.cell} column content={'space-between'}>
                        <Flex column>
                            <Text
                                className={styles.textClamp}
                                style={{ lineHeight: '18px' }}
                                margin="0 0 6px 0"
                                fSize="14px"
                                color={'gray-new-1'}
                            >
                                {row.post_text}
                            </Text>
                            <Text margin="0 0 16px 0" fSize="10px" color={'gray-new-3'}>
                                {new Date(row.published_at).toLocaleDateString()}
                            </Text>
                        </Flex>
                        <Flex align={'center'}>
                            <Text semibold color={'gray-new-1'} fSize="12px">
                                {row.blogger_username}
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            ${row.estimated_budget.toLocaleString()}
                        </Text>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {row.views.toLocaleString()}
                        </Text>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {row.likes.toLocaleString()}
                        </Text>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {row.comments.toLocaleString()}
                        </Text>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {(row.engagement_rate * 100).toFixed(1)}%
                        </Text>
                    </Flex>
                </Flex>
            ))}
            {loading && <Loader style={{ margin: 'auto' }} />}
        </div>
    )
}

export default Table
