import React, { FC } from 'react'
import styles from './params-list.module.scss'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg'
import { useTranslation } from 'react-i18next'
import Tooltip from '@/components/Tooltip'

interface Value {
    label: string
    value: string
}

interface ICriteria {
    title: string
    tooltip?: string
    values: Value[]
    type: string
}

interface CriteriaListProps {
    criteria: ICriteria[]
    onEdit: () => void
    onRemove: (criterionIndex: number, valueIndex: number) => void
    type: string
    reportName: string
    showReportName?: boolean
}

const ParamsList: FC<CriteriaListProps> = ({ criteria, onEdit, onRemove, type, reportName, showReportName }) => {
    const { t } = useTranslation()
    const titles = {
        params: 'target_audience.main_criteria.main_criteria',
        interests: 'target_audience.interests',
        additional: 'target_audience.additional_params.additional_parameters',
    }

    return (
        <Flex className={styles.criteriaContainer}>
            <Flex className={styles['title-wrapper']}>
                <Text fSize={'14px'} semibold color={'gray-new-4'}>
                    {t(titles[type as keyof typeof titles])}
                </Text>
                <EditIcon onClick={onEdit} style={{ cursor: 'pointer' }} />
            </Flex>
            {showReportName && (
                <div className={styles.criterion}>
                    <div className={styles.criterionHeader}>
                        <Text fSize="12px" semibold color="gray-new-4">
                            {t('target_audience.report_title')}
                        </Text>
                    </div>
                    <div className={styles.criterionValues}>
                        <Text fSize="14px" color="gray-new-1">
                            {reportName}
                        </Text>
                    </div>
                </div>
            )}
            {criteria?.map((criterion, criterionIndex) => (
                <div key={criterion.title} className={styles.criterion}>
                    <div className={styles.criterionHeader}>
                        <Text fSize="12px" semibold color="gray-new-4">
                            {t(criterion.title)}
                        </Text>
                        <Tooltip title={t(criterion?.tooltip || '')} placement={'right'}>
                            <span className={styles.info}>i</span>
                        </Tooltip>
                    </div>
                    <div className={styles.criterionValues}>
                        {criterion?.values?.map((value, valueIndex) => (
                            <span
                                onClick={() => onRemove(criterionIndex, valueIndex)}
                                key={valueIndex + value.label}
                                className={styles.value}
                            >
                                {value.label} <span className={styles.remove}>×</span>
                            </span>
                        ))}
                    </div>
                </div>
            ))}
        </Flex>
    )
}

export default ParamsList
