import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import LegacyNotification from '@/components/Notifier/MessageTypes/LegacyNotification'
// import { push } from '@/components/Notifier/PushNotification'
import { parseBloggerInput } from '@/_helpers/getNames'
import { useAsyncCallback } from '@/_helpers/Hooks/useAsyncCallback'
import useToken from '@/_helpers/Hooks/useToken'
import { BloggerArea, Flex } from '@/_yc'
import Popup from '@/_yc/Popup/Popup'
// import { AxiosError } from 'axios'
import { IPlatformTypes, ListsEasy } from '@/pages/Lists/model/types'

import Text from '@/components/Text'

import cn from './MassParsingPopup.module.scss'
// import { parsingRequest } from './ParsingRequest'
import { IParsedBlogger } from './types'
import LoadingButton from '@/components/Share/LoadingButton/LoadingButton'
import { ListSelector } from './ListSelector/ListSelector'
// import { ICustomListInfo } from '@/pages/Lists/model/types'
import { AddToListReq } from '@/pages/Lists/model/service'
import { useDispatch } from 'react-redux'
import notif from '@/components/Notifier/model/actions.js'
import { updateQTY } from '@/pages/Lists/model/actions'
import { RemoveListINST, RemoveListYT } from '../MassAddToList/_model/actions'
import { track } from '@amplitude/analytics-browser'
import { Platform } from '@/pages/Reports/types'
import CSVLoader from './CSVLoader'
import InstagramIcon from '@/components/Icons/UI/InstagramIEDisabled'
import YoutubeIcon from '@/components/Icons/UI/YouTubeIEDisabled'
import TwitchIcon from '@/components/Icons/UI/TwitchIEDisabled'
import TelegramIcon from '@/components/Icons/UI/TelegraDisabled'
import TikTokIcon from '@/components/Icons/UI/TikTokIEDisabled'
import VkIcon from '@/components/Icons/UI/VkDisabled'
import { Tooltip, withStyles } from '@material-ui/core'
import Icons from '@/components/Icons'
import { placeholders } from '@/pages/Lists/const'

export type IActionType = 'bulk' | 'csv'

interface IMassParsingPopup {
    isOpen: boolean
    close: () => void
    title: string
    subtitle: string
    actionType: IActionType
    setActionType: Function
}

const style = {
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
    zIndex: 120,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}

const LIMIT = 100

const CustomTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#5D697D',
        color: '#ffffff',
        fontSize: 10,
        padding: 10,
        borderRadius: 4,
        fontFamily: 'Montserrat',
        fontWeight: 500,
    },
    arrow: {
        color: '#5D697D',
    },
}))(Tooltip)

export const MassParsingPopup: FC<IMassParsingPopup> = ({ close, isOpen, ...props }) => {
    const { t, i18n } = useTranslation()

    const [bloggers, setBloggers] = useState<IParsedBlogger[]>([])
    const [uniqueBloggers] = useState<Set<string>>(new Set<string>())
    const [loading, setLoading] = useState(false)
    const [curPlatform, setCurPlatform] = useState<Platform>(Platform.Instagram)
    const dispatch = useDispatch()
    const [isListChosenER, setIsListChosenER] = useState<boolean | undefined>()

    const [curListItem, setCurListItem] = useState<ListsEasy>()

    const massAddList = useMemo(() => {
        return {
            instUsernameList: bloggers.filter((blogger) => blogger.type === Platform.Instagram).map((b) => b.index),
            ytList: bloggers.filter((blogger) => blogger.type === Platform.YouTube).map((b) => b.index),
            twitchList: bloggers.filter((blogger) => blogger.type === Platform.Twitch).map((b) => b.index),
            instIdList: [],
            ytUsernamesList: bloggers.filter((blogger) => blogger.type === 'yt_username').map((b) => b.index),
            vk_idsList: bloggers.filter((blogger) => blogger.type === Platform.Vk).map((b) => b.index),
            telegram_idsList: bloggers.filter((blogger) => blogger.type === Platform.Tg).map((b) => b.index),
            tikTokIdsList: bloggers.filter((blogger) => blogger.type === Platform.TikTok).map((b) => b.index),
        }
    }, [bloggers])

    const token = useToken()

    const handleDelete = useCallback(
        (index: string) => {
            uniqueBloggers.delete(index)
            setBloggers((prev) => prev.filter((blogger) => blogger.index !== index))
        },
        [uniqueBloggers]
    )

    const parseInputBloggers = useCallback(
        (inputBloggers: string[]) => {
            const parsedBloggers: IParsedBlogger[] = []

            for (let blogger of inputBloggers) {
                const { name, type } = parseBloggerInput(blogger, curPlatform)

                if (type === 'yt_username') {
                    if (curPlatform !== Platform.YouTube) {
                        //checks cases, when there is yt_username on inst or twitch page
                        dispatch(notif.info(t('aud_insides.status.only_platform_WARN', { platform: curPlatform })))
                        continue
                    }
                } else if (type !== curPlatform) {
                    dispatch(notif.info(t('aud_insides.status.only_platform_WARN', { platform: curPlatform })))
                    continue
                }

                if (uniqueBloggers.has(name)) continue
                parsedBloggers.push({
                    label: blogger,
                    index: name,
                    type: type,
                    onClick: handleDelete,
                })

                uniqueBloggers.add(name)
            }
            return parsedBloggers
        },
        [uniqueBloggers, handleDelete, curPlatform, dispatch, t]
    )

    const separateBloggers = (inputBloggers: string[]) => {
        let separatedBloggers: string[] = []

        for (let blogger of inputBloggers) {
            separatedBloggers = separatedBloggers.concat(blogger.split(/[,;\t]/))
        }

        return separatedBloggers.filter((blogger) => blogger !== '')
    }

    const changeBloggers = (inputBloggers: string[]) => {
        const separatedBloggers = separateBloggers(inputBloggers)
        const parsedBloggers: IParsedBlogger[] = parseInputBloggers(separatedBloggers)
        const numOfBloggers = LIMIT - bloggers.length
        setBloggers((prev) => prev.concat(parsedBloggers.slice(0, numOfBloggers)))
    }

    const massAddReq = (token: string, listInfo: ListsEasy) => {
        let promiseAr = []
        if (massAddList?.instIdList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, massAddList?.instIdList))
        }
        if (massAddList?.instUsernameList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, [], [], massAddList?.instUsernameList))
        }
        if (massAddList?.ytList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, undefined, massAddList?.ytList))
        }
        if (massAddList?.twitchList.length !== 0) {
            promiseAr.push(AddToListReq(token, listInfo.id, undefined, undefined, massAddList?.twitchList))
        }
        if (massAddList?.vk_idsList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.vk_idsList
                )
            )
        }
        if (massAddList?.telegram_idsList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.telegram_idsList
                )
            )
        }

        if (massAddList?.ytUsernamesList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.ytUsernamesList
                )
            )
        }
        if (massAddList?.tikTokIdsList.length !== 0) {
            promiseAr.push(
                AddToListReq(
                    token,
                    listInfo.id,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    massAddList?.tikTokIdsList?.map((str) => str?.replace(/^@/, ''))
                )
            )
        }
        if (promiseAr.length === 0) {
            return
        }

        Promise.all(promiseAr)
            .then((res) => {
                dispatch(
                    notif.success([
                        t('aud_insides.status.added_to_list_LINK'),
                        { listName: listInfo.name, listID: listInfo.id },
                    ])
                )
                const curCount = Math.max(...res.map((item) => parseInt(item.total_count)))
                dispatch(
                    updateQTY({
                        id: listInfo.id,
                        qty: curCount,
                    })
                )
                dispatch(RemoveListYT())
                dispatch(RemoveListINST())
                setTimeout(() => window.location.reload(), 500)
            })
            .catch((e) => {
                dispatch(notif.error(t('aud_insides.status.error')))
            })
    }

    const addToList = (listInfo: ListsEasy) => {
        dispatch(notif.loading(t('aud_insides.status.loading')))
        if (
            !token ||
            (massAddList?.instUsernameList.length === 0 &&
                massAddList?.ytList.length === 0 &&
                massAddList?.twitchList.length === 0 &&
                massAddList?.ytUsernamesList.length === 0 &&
                massAddList?.vk_idsList.length === 0 &&
                massAddList?.telegram_idsList.length === 0 &&
                massAddList?.tikTokIdsList.length === 0)
        ) {
            dispatch(notif.error(t('aud_insides.status.error')))
            return
        }

        massAddReq(token, listInfo)
        track(`add_to_list_aud_insights_mass`)
    }

    const [parse] = useAsyncCallback(async () => {
        if (!curListItem) {
            setIsListChosenER(true)
            return Promise.reject()
        }
        if (!token) return Promise.reject()
        setLoading(true)
        const bloggersToParse = bloggers.filter((blogger) => blogger.type === Platform.Instagram)
        const channelsIdsToParse = bloggers
            .filter((blogger) => blogger.type === Platform.YouTube)
            .map((b) => `www.youtube.com/channel/${b.index}`)

        const channelUsernamesToParse = bloggers
            .filter((blogger) => blogger.type === 'yt_username')
            .map((b) => `www.youtube.com/channel/@${b.index}`)

        const channelsToParse = channelsIdsToParse.concat(channelUsernamesToParse)

        if (curListItem) {
            addToList(curListItem)
        }

        if (bloggersToParse.length === 0 && channelsToParse.length === 0) {
            // push(new LegacyNotification(t('common_error'), 'error'))
            close()
            setLoading(false)
            return Promise.resolve()
        }

        // try {
        //     await parsingRequest(
        //         token,
        //         bloggersToParse.map((b) => ({ blogger_url: b.index })),
        //         channelsToParse.map((b) => ({ blogger_url: b }))
        //     )
        //     close()
        //     setLoading(false)
        // } catch (e) {
        //     // if (e?.isAxiosError) {
        //     //     const err: AxiosError = e
        //     //     if (err.response?.status === 400) {
        //     //         push(new LegacyNotification(t('aud_insides.search_input.invalid_username'), 'error'))
        //     //     } else if (err.response?.status === 403) {
        //     //         push(new LegacyNotification(t('report.alerts.renew'), 'error'))
        //     //     } else {
        //     //         push(new LegacyNotification(t('common_error'), 'error'))
        //     //     }
        //     // } else {
        //     //     push(new LegacyNotification(t('common_error'), 'error'))
        //     // }
        //     setLoading(false)
        //     close()
        // }
    }, [token, bloggers, curListItem])

    const [file, setFile] = useState<File | undefined>(undefined)

    const dropState = () => {
        setBloggers([])
        uniqueBloggers.clear()
    }
    const changePlatform = (plat: Platform) => {
        setCurPlatform(plat)
        dropState()
        setFile(undefined)
    }

    const platformButton: { icon: ReactNode; pl: Platform; text: string; disabled?: boolean; tooltip?: string }[] = [
        {
            icon: <InstagramIcon />,
            pl: Platform.Instagram,
            text: t('aud_insides.mass_parsing.inst'),
            tooltip: 'Instagram',
        },
        {
            icon: <YoutubeIcon />,
            pl: Platform.YouTube,
            text: t('aud_insides.mass_parsing.yt'),
            tooltip: 'YouTube',
        },
        {
            icon: <TwitchIcon />,
            pl: Platform.Twitch,
            text: t('aud_insides.mass_parsing.tw'),
            tooltip: 'Twitch',
            disabled: true,
        },
        {
            icon: <VkIcon />,
            pl: Platform.Vk,
            text: t('aud_insides.mass_parsing.vk'),
            tooltip: 'Vk',
            disabled: false,
        },
        {
            icon: <TelegramIcon />,
            pl: Platform.Tg,
            text: t('aud_insides.mass_parsing.tg'),
            tooltip: 'Telegram',
            disabled: false,
        },
        {
            icon: <TikTokIcon />,
            pl: Platform.TikTok,
            text: t('aud_insides.mass_parsing.tiktok'),
            tooltip: 'TikTok',
            disabled: false,
        },
    ]

    return (
        <div className={cn.root}>
            <Popup style={style} isOpen={isOpen} onClose={close}>
                <div className={cn.popup}>
                    <Flex content={'space-between'} align="center">
                        <Text fSize={20}>{props.title}</Text>
                        <Icons name="close" className={cn['close-icon']} onClick={() => close()} />
                    </Flex>

                    <Text fSize={14} color="gray-new-4" margin="10px 0 10px 0">
                        {props.subtitle}
                    </Text>

                    <>
                        <Flex content={'space-between'} className={cn.platforms}>
                            {platformButton.map((item, i) => (
                                <CustomTooltip key={item.text + i} title={item.tooltip || ''} placement="top" arrow>
                                    <Flex
                                        className={`${cn['icon-wrapper']} ${
                                            curPlatform === item.pl ? cn['active'] : ''
                                        } ${item.disabled ? cn['disabled'] : ''}`}
                                        onClick={item.disabled ? undefined : () => changePlatform(item.pl)}
                                    >
                                        {item.icon}
                                    </Flex>
                                </CustomTooltip>
                            ))}
                        </Flex>
                    </>

                    <ListSelector curItem={curListItem} setCurItem={setCurListItem} error={isListChosenER} />

                    {props.actionType === 'csv' ? (
                        <CSVLoader
                            changeBloggers={changeBloggers}
                            dropCSVfile={dropState}
                            file={file}
                            setFile={setFile}
                        >
                            <div style={{ marginTop: '16px' }}>
                                <BloggerArea value={bloggers} onChange={changeBloggers} limit={LIMIT} />
                            </div>
                        </CSVLoader>
                    ) : (
                        <div style={{ marginTop: '16px' }}>
                            <Flex content="space-between" align="center">
                                <Text fSize={'12px'} color="gray-new-1">
                                    {t('outreach.templates.influencers_list.popup.title')}
                                </Text>
                                <Text fSize={'12px'} color="gray-new-3">
                                    {' '}
                                    {bloggers.length} / {LIMIT}
                                </Text>
                            </Flex>
                            <BloggerArea
                                // placeholder={t('bulk_add.addSocialLinks')}
                                helperText={
                                    placeholders[i18n.language === 'ru' ? 'ru' : 'en'][curPlatform as IPlatformTypes]
                                }
                                value={bloggers}
                                onChange={changeBloggers}
                                limit={LIMIT}
                            />
                        </div>
                    )}

                    <Flex content="flex-end" margin="20px 0 0 0">
                        <LoadingButton
                            noDownloadIcon
                            onClick={parse}
                            width={'224px'}
                            variant={'primary'}
                            loading={loading}
                            // disabled={!curListItem}
                        >
                            {t('aud_insides.mass_parsing.add')}
                        </LoadingButton>

                        {/* <Button onClick={close} width={'156px'} variant={'outline'}>
                            {t('aud_insides.mass_parsing.cancel')}
                        </Button> */}
                    </Flex>
                </div>
            </Popup>
        </div>
    )
}
