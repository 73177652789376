import React, { useEffect, useState, useRef, useCallback } from 'react'
import Table from '@/pages/Competitor/Monitoring/components/BloggersList/components/Table'
import styles from './bloggers.module.scss'
import SearchInput from '@/pages/Competitor/components/SearchInput'
import DatePicker from '@/pages/Competitor/Monitoring/components/BloggersList/components/DatePicker'
import { Flex } from '@/_yc'
import TabsHeader from '@/pages/Competitor/Monitoring/components/BloggersList/components/TabsHeader'
import TopBloggersTable from '@/pages/Competitor/Monitoring/components/BloggersList/components/TopBloggersTable'
import { getPostsInfoReq, getTop10AccountsReq } from '@/pages/Competitor/module/services'
import useToken from '@/_helpers/Hooks/useToken'
import { useLocation, useParams } from 'react-router-dom'
import { PostInfo, TopBlogger } from '@/pages/Competitor/module/types'
import { formatDate } from '@/pages/Competitor/module/utils'

const TablePage: React.FC = () => {
    const [type, setType] = React.useState<'all' | 'top'>('all')
    const [postsDataAll, setPostsDataAll] = React.useState<PostInfo[]>([])
    const [topTableData, setTopTableData] = React.useState<TopBlogger[]>([])
    const [loading, setLoading] = React.useState(false)

    // Фильтры
    const [searchChannel, setSearchChannel] = useState('')
    const [searchName, setSearchName] = useState('')
    const [startDate, setStartDate] = useState<Date | null>(null)
    const [endDate, setEndDate] = useState<Date | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    const { id } = useParams<{ id: string }>()
    const token = useToken()
    const { pathname } = useLocation()
    const platformSlug = pathname.split('/').pop()

    const setTypeHandler = (type: 'all' | 'top') => {
        setType(type)
    }

    const debounceTimer = useRef<NodeJS.Timeout | null>(null)

    const getPostsInfoAll = async (page: number, append: boolean = false) => {
        try {
            setLoading(true)
            const response = await getPostsInfoReq(token || '', id, platformSlug || '', {
                bloggerUsername: searchName,
                searchText: searchChannel,
                startDate: formatDate(startDate),
                endDate: formatDate(endDate),
                page,
            })
            setPostsDataAll((prev) => (append ? [...prev, ...response.posts_data] : response.posts_data))
            setTotalPages(response.total_pages)
        } catch (e) {
            console.error(e)
            if (!append) setPostsDataAll([])
        } finally {
            setLoading(false)
        }
    }

    const getTopBloggersReq = async () => {
        try {
            setLoading(true)
            const response = await getTop10AccountsReq(token || '', id, platformSlug || '')
            setTopTableData(response.top_10_bloggers)
            setLoading(false)
        } catch (e) {
            setLoading(false)
            console.log(e)
        }
    }

    const handleSearchChannel = useCallback((value: string) => {
        setSearchName(value)
    }, [])

    const handleSearchName = useCallback((value: string) => {
        setSearchChannel(value)
    }, [])

    useEffect(() => {
        if (debounceTimer.current) {
            clearTimeout(debounceTimer.current)
        }

        debounceTimer.current = setTimeout(() => {
            setCurrentPage(1)
            if (type === 'all') {
                getPostsInfoAll(1, false)
            } else if (type === 'top') {
                getTopBloggersReq()
            }
        }, 500)

        return () => {
            if (debounceTimer.current) {
                clearTimeout(debounceTimer.current)
            }
        }
        //eslint-disable-next-line
    }, [searchChannel, searchName, startDate, endDate, type])

    useEffect(() => {
        if (currentPage > 1 && type === 'all' && totalPages >= currentPage) {
            getPostsInfoAll(currentPage, true)
        }
        //eslint-disable-next-line
    }, [currentPage, type, totalPages])

    const handleScroll = useCallback(() => {
        const isBottom =
            window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 200

        if (isBottom && !loading && postsDataAll.length >= 10) {
            setCurrentPage((prevPage) => prevPage + 1)
        }
    }, [loading, postsDataAll])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [handleScroll])

    return (
        <div className={styles.pageContainer}>
            <TabsHeader active={type} setTypeHandler={setTypeHandler} />
            {type === 'all' && (
                <div className={styles.filtersContainer}>
                    <Flex style={{ gap: '16px', width: '100%' }}>
                        <SearchInput onSearch={handleSearchChannel} placeholder="Найти по каналу" />
                        <SearchInput onSearch={handleSearchName} placeholder="Найти по названию" />
                    </Flex>
                    <Flex>
                        <Flex margin="0 16px 0 0" style={{ maxWidth: '160px' }}>
                            <DatePicker
                                prefix={'С'}
                                locale={'en'}
                                value={startDate}
                                onChange={(date) => setStartDate(date)}
                            />
                        </Flex>
                        <Flex style={{ maxWidth: '160px' }}>
                            <DatePicker
                                prefix={'По'}
                                locale={'en'}
                                value={endDate}
                                onChange={(date) => setEndDate(date)}
                            />
                        </Flex>
                    </Flex>
                </div>
            )}
            {type === 'all' && <Table data={postsDataAll} loading={loading} />}
            {type === 'top' && <TopBloggersTable loading={loading} data={topTableData} />}
        </div>
    )
}

export default TablePage
