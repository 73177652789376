import React from 'react'
import styles from './tableRow.module.scss'
import { Flex } from '@/_yc'
import Button from '@/components/Button'
import Text from '@/components/Text'
import CustomCheckbox from '@/components/CustomCheckbox'
import { Tooltip, withStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Keyword = {
    label: string
}

type Blogger = {
    avatar: string
    count: number
}

type TableRowProps = {
    id: number
    brand: string
    inn: string
    keywords: Keyword[]
    postsFound: number
    bloggers: Blogger[]
    reportReady: boolean
    selectedItems: number[]
    onSelect: (id: number, checked: boolean) => void
}

const CustomTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#FFFFFF',
        padding: '16px',
        fontSize: '10px',
        fontWeight: 500,
        textAlign: 'center',
        cursor: 'pointer',
        boxShadow: '0px 2px 10px rgba(57, 63, 73, 0.20)',
    },
    arrow: {
        color: '#FFFFFF',
    },
}))(Tooltip)

const TableRow: React.FC<TableRowProps> = ({
    brand,
    inn,
    keywords,
    postsFound,
    bloggers,
    reportReady,
    selectedItems,
    onSelect,
    id,
}) => {
    const history = useHistory()
    const { t } = useTranslation()

    const openReport = (id: number) => {
        history.push(`/ads-new/monitoring/${id}/instagram`)
    }

    return (
        <div className={styles.row}>
            <Flex content="center">
                <CustomCheckbox isChecked={selectedItems.includes(id)} onChange={(checked) => onSelect(id, checked)} />
            </Flex>
            {/* Бренд / ИНН */}
            <Flex column>
                <Text fSize="14px" semibold color={'gray-new-1'}>
                    {brand}
                </Text>
                <Text fSize="12px" semibold color={'gray-new-3'} className={styles.inn}>
                    {inn}
                </Text>
            </Flex>

            <div className={styles.cell}>
                <div className={styles.keywords}>
                    {keywords.slice(0, 2).map((keyword, idx) => (
                        <span key={idx} className={styles.keyword}>
                            {keyword.label}
                        </span>
                    ))}
                    {keywords.length > 2 && (
                        <CustomTooltip
                            title={
                                <div className={styles['keywords-tooltip']}>
                                    {keywords.map((keyword, idx) => (
                                        <span key={idx} className={styles.keyword}>
                                            {keyword.label}
                                        </span>
                                    ))}
                                </div>
                            }
                            arrow
                            placement="top"
                        >
                            <span className={styles.moreCount}>+{keywords.length - 2}</span>
                        </CustomTooltip>
                    )}
                </div>
            </div>

            {/* Найдено постов */}
            <Text className={styles.cell} fSize="12px" color={'gray-new-1'}>
                {postsFound}
            </Text>

            {/* Найдено блогеров */}
            <div className={styles.cell}>
                <div className={styles.bloggers}>
                    {bloggers
                        .map((blogger, idx) => (
                            <img key={idx} src={blogger.avatar} alt={`Blogger ${idx}`} className={styles.avatar} />
                            // <div key={idx} className={styles.avatar} />
                        ))
                        .slice(0, 5)}
                    {bloggers.length > 5 && (
                        <span style={{ marginLeft: '-15px', background: '#F2F4F7' }} className={styles.moreCount}>
                            +{bloggers.length - 5}
                        </span>
                    )}
                </div>
            </div>

            <div className={styles.cell}>
                <Button className={styles.openReport} disabled={!reportReady} onClick={() => openReport(id)}>
                    {t('competitor_analysis.open_report')}
                </Button>
            </div>
        </div>
    )
}

export default TableRow
