import React, { FC, useState } from 'react'
import TableHeader from '@/pages/Competitor/components/TableCompetitor/components/TableHeader'
import TableRow from '@/pages/Competitor/components/TableCompetitor/components/TableRow'
import EmptyContent from '@/pages/Competitor/components/TableCompetitor/components/EmptyContent'
import { TransformedRow } from '@/pages/Competitor/module/types'
import Loader from '@/components/Loader'

type TableCompetitorProps = {
    data: TransformedRow[]
    loading: boolean
    handleOpen: () => void
    handleDelete: (ids: number[]) => void
}

const Index: FC<TableCompetitorProps> = ({ data, loading, handleOpen, handleDelete }) => {
    const [selectedItems, setSelectedItems] = useState<number[]>([])

    const handleSelectItem = (id: number | 'all', checked: boolean) => {
        setSelectedItems((prev) => {
            if (id === 'all') {
                return checked ? data.map((item) => item.id) : []
            }
            return checked ? [...prev, id] : prev.filter((itemId) => itemId !== id)
        })
    }

    const deleteItems = (ids: number[]) => {
        handleDelete(ids)
        setSelectedItems([])
    }

    return (
        <div>
            <TableHeader isDelete={selectedItems.length > 0} handleDelete={() => deleteItems(selectedItems)} />
            {data.length > 0 ? (
                data.map((row) => (
                    <TableRow
                        key={row.id}
                        id={row.id}
                        brand={row.brand}
                        inn={row.inn}
                        keywords={row.keywords}
                        postsFound={row.postsFound}
                        bloggers={row.bloggers}
                        reportReady={row.reportReady}
                        onSelect={handleSelectItem}
                        selectedItems={selectedItems}
                    />
                ))
            ) : (
                <>
                    {!loading && <EmptyContent handleOpen={handleOpen} />}
                    {loading && <Loader style={{ margin: 'auto' }} />}
                </>
            )}
        </div>
    )
}

export default Index
