import Service from '@/_helpers/Service'
import {
    Company,
    EditMonitoringCampaignParams,
    MonitoringMetrics,
    MonitoringPostsInfoResponse,
    Top10AccountsResponse,
} from '@/pages/Competitor/module/types'

const createCompanyService = new Service<{
    name: string
    query_words: string[]
}>('')

export const createCompanyReq = (token: string, name: string, queryWords: string[]) =>
    createCompanyService.call(
        {
            url: '/api/monitoring_campaigns/create/',
            method: 'POST',
            data: {
                name,
                query_words: queryWords,
            },
        },
        token
    )

const getCompaniesService = new Service<Company[]>('')

export const getCompaniesReq = (token: string) =>
    getCompaniesService.call(
        {
            url: '/api/monitoring_campaigns/list/',
            method: 'GET',
        },
        token
    )

const getMetricsService = new Service<MonitoringMetrics>('')

export const getMetricsReq = (token: string, monitoringId: number, socialNetwork: string) =>
    getMetricsService.call(
        {
            url: `/api/monitoring_campaigns/details/metrics/`,
            method: 'GET',
            params: {
                monitoring_id: monitoringId,
                social_network: socialNetwork,
            },
        },
        token
    )

const editMonitoringCampaignService = new Service<void>('')

export const editMonitoringCampaignReq = (token: string, params: EditMonitoringCampaignParams) =>
    editMonitoringCampaignService.call(
        {
            url: `/api/monitoring_campaigns/edit/`,
            method: 'PATCH',
            data: {
                monitoring_id: params.monitoring_id,
                name: params.name,
                inn: params.inn,
                new_keywords: params.new_keywords,
                exclusion_keywords_query: params.exclusion_keywords_query,
                hashtags: params.hashtags,
            },
        },
        token
    )

const getPostsInfoService = new Service<MonitoringPostsInfoResponse>('')

export const getPostsInfoReq = (
    token: string,
    monitoringId: number | string,
    socialNetwork: string = 'instagram',
    options?: {
        bloggerUsername?: string
        searchText?: string
        startDate?: string | Date | null
        endDate?: string | Date | null
        page?: number
        postsPerPage?: number
    }
) =>
    getPostsInfoService.call(
        {
            url: '/api/monitoring_campaigns/details/posts_info/',
            method: 'GET',
            params: {
                monitoring_id: monitoringId,
                social_network: socialNetwork,
                blogger_username: options?.bloggerUsername,
                search_text: options?.searchText,
                start_date: options?.startDate,
                end_date: options?.endDate,
                page: options?.page || 1,
                posts_per_page: options?.postsPerPage || 10,
            },
        },
        token
    )

const getTop10AccountsService = new Service<Top10AccountsResponse>('')

export const getTop10AccountsReq = (
    token: string,
    monitoringId: number | string,
    socialNetwork: string = 'instagram',
    options?: {
        search?: string
    }
) =>
    getTop10AccountsService.call(
        {
            url: '/api/monitoring_campaigns/details/top_10_accounts/',
            method: 'GET',
            params: {
                monitoring_id: monitoringId,
                social_network: socialNetwork,
                search: options?.search,
            },
        },
        token
    )
const deleteMonitoringCampaignService = new Service<void>('')

export const deleteMonitoringCampaignReq = (token: string, monitoringIds: number[]) =>
    deleteMonitoringCampaignService.call(
        {
            url: '/api/monitoring_campaigns/delete_campaigns/',
            method: 'DELETE',
            data: {
                monitoring_ids: monitoringIds,
            },
        },
        token
    )
