import React, { FC, useEffect, useState, useMemo } from 'react'
import styles from './modal-download-info.module.scss'
import Button from '@/components/Button'
import { useSelector, useDispatch } from 'react-redux'
import IconCloce from '@/components/Icons/UI/Close'
import Download from '@/components/Icons/UI/Download'

import { RootStore } from '@/_helpers/StoreType'
import { getListLockedCountReq } from '@/pages/Lists/model/service'
import useToken from '@/_helpers/Hooks/useToken'
import { setListLockedCount } from '@/pages/Lists/model/actions'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import Icons from '@/components/Icons'
import Loader from '@/components/Loader'
import { useTranslation } from 'react-i18next'

interface ModalDownloadInfoProps {
    isOpen: boolean
    closeModal: () => void
    callback?: (id: number) => void
}

const ModalDownloadInfo: FC<ModalDownloadInfoProps> = ({ isOpen, closeModal, callback }) => {
    const dispatch = useDispatch()

    const [isLoading, setIsLoading] = useState(false)
    const token = useToken()
    const { t } = useTranslation()
    // const t = useSpaceTranslation('aud_insides.download_modal')
    // let trans = useSpaceTranslation('ads_explorer.download_excel')
    // const trans2 = useSpaceTranslation('outreach.templates.influencers_list')

    const activeListId = useSelector((store: RootStore) => store?.AudienceInsights?.ActiveListId)
    const ListInfo = useSelector((store: RootStore) => store?.AudienceInsights?.ListsInfo)
    const curList = ListInfo.find((list) => list.id === activeListId)

    const reportsCount = useSelector((store: RootStore) => store?.HeaderMenu_.ReportCounter_.reportsCount)

    const lockedBloggersCount = useSelector((store: RootStore) => store?.AudienceInsights?.listLockedCount)

    const notEnoughTokens = useMemo(() => {
        if (reportsCount === null) {
            return false
        }
        return lockedBloggersCount > reportsCount
    }, [lockedBloggersCount, reportsCount])

    useEffect(() => {
        if (!token) return
        if (isOpen && activeListId) {
            setIsLoading(true)
            getListLockedCountReq(token, activeListId)
                .then((res) => {
                    dispatch(setListLockedCount(res.count))
                    setIsLoading(false)
                })
                .catch(() => setIsLoading(false))
        }
    }, [token, isOpen, activeListId, dispatch])

    const closeHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        closeModal()
    }

    const callbackHandler = (e: React.MouseEvent) => {
        e.stopPropagation()
        callback && callback(activeListId)
    }

    return (
        <>
            {isOpen && (
                <div className={styles.root} onClick={closeHandler}>
                    <div className={styles.popup}>
                        <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                            <Flex column>
                                <Flex content="space-between" align="center">
                                    <Text
                                        margin="0 0 10px 0"
                                        fSize="20px"
                                        semibold
                                        color="gray-new-1"
                                        component="h3"
                                        style={{ lineHeight: '24px' }}
                                    >
                                        {t('aud_insides.download_modal.title')}
                                    </Text>
                                    <button className={styles.closeButton} onClick={closeModal}>
                                        <IconCloce />
                                    </button>
                                </Flex>

                                <Text style={{ lineHeight: '18px' }} color="gray-new-3" fSize="14px">
                                    {t('aud_insides.download_modal.subtitle').replace(/csv/i, '.xlsx')}
                                </Text>
                            </Flex>
                            <div className={styles.infoSection}>
                                <div className={styles.infoItem}>
                                    <Text component="span" color="gray-new-3" fSize="12px">
                                        {t('ads_explorer.download_excel.selected')}
                                    </Text>
                                    <Flex align="center">
                                        <Text
                                            semibold
                                            fSize="18px"
                                            margin="0 4px 0 0"
                                            component="strong"
                                            color="gray-new-1"
                                        >
                                            {curList?.qty || 0}{' '}
                                        </Text>
                                        <Text margin="0 4px 0 0" fSize="14px" component="span" color="gray-new-1">
                                            {t('outreach.templates.influencers_list.bloggers')}{' '}
                                        </Text>
                                        <Icons name={'ads-explorer'} />
                                    </Flex>
                                </div>
                                <div className={styles.infoItem}>
                                    <Text component="span" color="gray-new-3" fSize="12px">
                                        {t('ads_explorer.download_excel.cost')}
                                    </Text>
                                    <Flex align="center">
                                        <Text
                                            semibold
                                            fSize="18px"
                                            margin="0 4px 0 0"
                                            component="strong"
                                            color="gray-new-1"
                                        >
                                            {lockedBloggersCount}{' '}
                                        </Text>
                                        <Text margin="0 4px 0 0" fSize="14px" component="span" color="gray-new-1">
                                            {' '}
                                            {t('ads_explorer.download_excel.tokens')}
                                        </Text>
                                        <Icons name={'flag'} />
                                    </Flex>
                                </div>
                            </div>
                            <Flex margin="0 0 20px 0" align="center">
                                <Text margin="0 4px 0 0" color="gray-new-3" fSize="14px">
                                    {t('aud_insides.download_modal.flags')}
                                </Text>
                                <Text margin="0 4px 0 0" semibold color="gray-new-1" fSize="14px">
                                    {reportsCount} {t('aud_insides.download_modal.tokens').toLowerCase()}
                                </Text>
                                <Icons name={'flag'} />
                            </Flex>

                            {notEnoughTokens && (
                                <p className={styles.errorMessage}>{t('aud_insides.download_modal.not_enough')}</p>
                            )}

                            <Button
                                className={styles.downloadButton}
                                variant="primary"
                                disabled={notEnoughTokens || isLoading}
                                onClick={callbackHandler}
                            >
                                {t('aud_insides.download_modal.open_btn')}
                                <Download />
                            </Button>
                        </div>
                        {isLoading && (
                            <Flex className={styles.loader}>
                                {' '}
                                <Loader />
                            </Flex>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default ModalDownloadInfo
