import React, { FC } from 'react'
import style from './tabs-header.module.scss'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as Icon } from '@/pages/Competitor/icons/picture.svg'
import { ReactComponent as IconUser } from '@/pages/Competitor/icons/user_square.svg'
import { useTranslation } from 'react-i18next'

interface ITabsHeader {
    active: 'all' | 'top'
    setTypeHandler: (type: 'all' | 'top') => void
}

const TabsHeader: FC<ITabsHeader> = ({ active, setTypeHandler }) => {
    const { t } = useTranslation()
    return (
        <Flex className={style.tabs}>
            <Flex
                className={`${style.tab} ${active === 'all' ? style.active : ''}`}
                align="center"
                onClick={() => setTypeHandler('all')}
            >
                <Icon />
                <Text component="span">{t('competitor_analysis.allMentions')}</Text>
            </Flex>
            <Flex
                className={`${style.tab} ${active === 'top' ? style.active : ''}`}
                align="center"
                onClick={() => setTypeHandler('top')}
            >
                <IconUser />
                <Text component="span">{t('competitor_analysis.top_bloggers')}</Text>
            </Flex>
        </Flex>
    )
}

export default TabsHeader
