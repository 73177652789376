const FileDownloader = (data, name = 'file', ext = 'pdf') => {
    if (!data) return
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${name}.${ext}`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
}
const downloadPdfFile  = async (url)=>{
    try {
        const tagLink = document.createElement('a')
        tagLink.href = url
        tagLink.download = 'super_export.pdf'
        document.body.appendChild(tagLink)
        tagLink.click()
        document.body.removeChild(tagLink)
    }catch (error) {
        console.error(error)
    }
    return null;
}

export { FileDownloader, downloadPdfFile }
