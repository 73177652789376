import React, { FC, useState } from 'react'
// import i18n from 'i18next'
// import { Avatar } from '@/_yc/'
import { shortStr } from '@/_helpers/_graphs/_tech'
// import { mapBy } from '@/_helpers/'
import { Avatar, Topics, Metrics } from '@yoloker/ui-collection'
import statusA from '@/_yc/Icons/statusA.png'
import useReportTranslation from '../Cards/useReportTranslation'
import { Flex } from '@/_yc'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { shortNum } from '@/_helpers/_graphs/_tech'

import cn from './Title.module.scss'
import { getShortString } from '@/_helpers/strings/getShortString'
import { GeneralInfo } from './GeneralInfo/GeneralInfo'

export interface IContacts {
    icon: 'location' | 'email' | 'phone'
    value?: string
    title?: string
    blur?: boolean
}
export interface ITitle {
    isVerified: boolean
    avatar: string
    fullName?: string
    username: string
    xs: boolean
    metrics: Array<{
        label: string
        value: number
    }>
    categories: Array<{
        label: string
    }>
    isDemo?: boolean
    contacts?: Array<IContacts>
    updatedDate?: {
        date?: string
        completed: boolean
    }
    er?: number
    reportReadyStatus: boolean
}
interface IMetric {
    name: string
    value: number | string
    tooltipText: string
    position: number
    inProgress?: boolean
}
const MetricsOrder = {
    er: 0,
    aq: 1,
    likes: 2,
    comments: 3,
    posts: 4,
}
let Title: FC<ITitle> = ({
    isVerified,
    avatar,
    fullName,
    username,
    metrics,
    categories,
    xs,
    updatedDate,
    ...props
}: ITitle) => {
    const t = useReportTranslation('horizontalMetrics')
    const topics = categories.map((item) => {
        return item.label
    })
    let bloggerMetrics: Array<IMetric> = metrics.map((item) => ({
        tooltipText: t(`tooltips.${item.label}`),
        name: t(item.label),
        position: MetricsOrder[item.label as keyof typeof MetricsOrder],
        inProgress: item.label === 'aq' ? props.reportReadyStatus : false,
        ...item,
    }))
    if (props.er)
        bloggerMetrics.push({
            tooltipText: t(`tooltips.er`),
            name: t('er'),
            position: MetricsOrder.er,
            value: shortNum(props.er * 100, 1, 2) + '%',
        })
    // bloggerMetrics.reverse()

    const [country, setCountry] = useState<string | undefined>(undefined)
    const [email, setEmail] = useState<string | undefined>(undefined)
    const [phone, setPhone] = useState<string | undefined>(undefined)
    props.contacts &&
        props.contacts.forEach((item) => {
            if (item.icon === 'location' && item.value !== country) {
                setCountry(item.value)
            }
            if (item.icon === 'email' && item.value !== email) {
                setEmail(item.value)
            }
            if (item.icon === 'phone' && item.value !== phone) {
                setPhone(item.value)
            }
        })

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'
    return (
        <div className={cn.root}>
            <GeneralInfo
                country={!isMobile ? country : undefined}
                email={!isMobile ? email : undefined}
                phone={!isMobile ? phone : undefined}
                updatedDate={updatedDate?.date}
                inProgress={!updatedDate?.completed}
                isDemo={props.isDemo}
            />
            <Flex content="space-between" className={cn['root--main']}>
                <Flex>
                    <Avatar platform="instagram" src={avatar} loadingAvatarWidth="" />
                    <div className={cn.container}>
                        <div className={cn.ava_name}>
                            <div className={cn.name}>
                                <div className={cn.fullname}>
                                    <span>{getShortString(fullName ? fullName : '', 19)}</span>
                                    {isVerified && (
                                        <div
                                            style={{ backgroundImage: `url(${statusA})` }}
                                            className={cn['icon--default']}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={cn.name}>
                            <a
                                href={`https://instagram.com/${username}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={!fullName ? cn.fullName : undefined}
                            >
                                @{xs ? shortStr(username, 11) : username}
                            </a>
                        </div>
                        <Flex style={{ overflow: 'hidden', maxWidth: '250px' }}>
                            <Topics containerSize="small" topics={topics} />
                        </Flex>
                    </div>
                </Flex>
                <Metrics metrics={bloggerMetrics} />
            </Flex>
            {isMobile && (
                <Flex margin="17px 0 0 0">
                    <GeneralInfo
                        country={isMobile ? country : undefined}
                        email={isMobile ? email : undefined}
                        phone={isMobile ? phone : undefined}
                        isDemo={props.isDemo}
                    />
                </Flex>
            )}
        </div>
    )
}

export default Title
