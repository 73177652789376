import React, { FC, useEffect, useMemo } from 'react'

import { Flex } from '@/_yc'
import Button from '@/components/Button'
import cn from './FiltersPanel.module.scss'
import { useAdapter, useSearch } from '@/pages/SearchPage/SearchAdapter'
import useSpaceTranslation from '@/_helpers/useSpaceTranslation'
import SearchButton from '@/pages/SearchPage/Bloggers/SearchPanel/Filters/SearchButton'
import FilterItem from '@/pages/SearchPage/Bloggers/SearchPanel/Filters/FilterItem'
import Chips from '../../../Bloggers/SearchPanel/Chips'

interface IFiltersPanel {}

export const FiltersPanel: FC<IFiltersPanel> = () => {
    const t = useSpaceTranslation('ads_explorer')

    const { filters } = useAdapter()
    const { restart, clearFilters } = useSearch()

    const arrays = useMemo(() => {
        return Object.entries(filters).map(([name, val]) => ({
            title: val.title,
            name,
            filters: Object.entries(val.filters),
        }))
    }, [filters])

    useEffect(() => {
        restart()
    }, [restart])

    const scrollToFilter = async (ref: any) => {
        ref.current.scrollIntoView({ block: 'end' })
    }

    return (
        <div className={cn.filtersPanel}>
            <Chips filtersState={false} filtersOpen={() => null} />

            <div className={cn['filtersPanel--filters']}>
                {arrays.map((category, i) => {
                    let sum = 1
                    return (
                        <div key={i}>
                            <div className={cn.grid}>
                                {category.filters.map(([name, Filter]) => {
                                    const result = (
                                        <FilterItem
                                            key={`${category.name}.${name}`}
                                            filter={Filter}
                                            start={sum}
                                            end={sum + Filter.options.space}
                                            categoryName={category.name}
                                            name={name}
                                            scrollToFilter={scrollToFilter}
                                        />
                                    )
                                    sum = (sum + Filter.options.space) % 5 || 1

                                    return result
                                })}
                            </div>
                        </div>
                    )
                })}
            </div>

            <div className={cn['filtersPanel--bottom-container']}>
                <Flex>
                    <SearchButton onClick={restart} />
                    <Button margin="0 0 0 30px" onClick={clearFilters}>
                        {t('filters.clear')}
                    </Button>
                </Flex>
            </div>
        </div>
    )
}
