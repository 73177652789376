import React, { CSSProperties, FC, memo, MouseEvent as Me } from 'react'

import cn from './Popup.module.scss'

import SearchItem from './SearchItem'
import Popper from '@/_yc/Popup'
import { AutocompleteResult } from '@/Reponses'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import ParseItem from './ParseItem'
import LoadCircle from '../LoadCircle/LoadCircle'
import { OpenReportPlace } from '@/_helpers/amplitude/types'
import { Flex } from '@/_yc'
import InstagramIcon from '@/components/Icons/UI/InstagramIEDisabled'
import YoutubeIcon from '@/components/Icons/UI/YouTubeIEDisabled'
import TwitchIcon from '@/components/Icons/UI/TwitchIEDisabled'
import TelegramIcon from '@/components/Icons/UI/TelegraDisabled'
import TikTokIcon from '@/components/Icons/UI/TikTokIEDisabled'
import VkIcon from '@/components/Icons/UI/VkDisabled'
import { useTgHidden, useTikTokHidden, useVkHidden } from '@/_helpers/Hooks/useIsInstagramHidden'
import { Tooltip, withStyles } from '@material-ui/core'

export interface IPopup {
    place: OpenReportPlace
    items: AutocompleteResult[]
    onChange: (external_id: string | number, username: string, fullName?: string) => void
    anchor: any
    isOpen: boolean
    style: CSSProperties
    onClose: (e: Me<Document, MouseEvent> | React.MouseEvent<HTMLDivElement, MouseEvent>) => void
    width: string | number
    onParsing: (username: string, social: string) => void
    error: boolean
    isLoading: boolean
    fullSearch?: string
    activeSocial: string
    setActiveSocial: (id: string) => void
}

const socialNetworks = [
    { id: 'instagram', label: 'Instagram', icon: InstagramIcon },
    { id: 'youtube', label: 'YouTube', icon: YoutubeIcon },
    { id: 'twitch', label: 'Twitch', icon: TwitchIcon },
    { id: 'telegram', label: 'Telegram', icon: TelegramIcon },
    { id: 'tiktok', label: 'TikTok', icon: TikTokIcon },
    { id: 'vk', label: 'VK', icon: VkIcon },
]

const CustomTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#5D697D',
        color: '#ffffff',
        fontSize: 10,
        padding: 10,
        borderRadius: 4,
        fontFamily: 'Montserrat',
        fontWeight: 500,
    },
    arrow: {
        color: '#5D697D',
    },
}))(Tooltip)

const Popup: FC<IPopup> = ({ onChange, ...props }) => {
    const size = useWindowWidth('mobile')
    const isVkHidden = useVkHidden()
    const isTgHidden = useTgHidden()
    const isTikTokHidden = useTikTokHidden()

    const handleActive = (id: string) => {
        props.setActiveSocial(id)
    }

    const isSocialNetworkDisabled = (id: string) => {
        switch (id) {
            case 'vk':
                return isVkHidden
            case 'telegram':
                return isTgHidden
            case 'tiktok':
                return isTikTokHidden
            default:
                return false
        }
    }

    return (
        <Popper isOpen={props.isOpen} onClose={props.onClose} anchor={props.anchor} style={props.style}>
            <div
                onClick={props.onClose}
                className={cn.root}
                style={{
                    width: size === 'mobile' ? 'calc(100vw - 20px)' : props.width,
                }}
            >
                <Flex style={{ padding: '12px' }}>
                    <Flex content="space-between" className={cn['icon-container']}>
                        {socialNetworks.map(({ id, icon: Icon, label }) => (
                            <CustomTooltip key={id} title={label} placement="top" arrow>
                                <Flex
                                    onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                                        if (!isSocialNetworkDisabled(id)) {
                                            handleActive(id)
                                        }
                                        e.stopPropagation()
                                    }}
                                    className={`${cn['icon-wrapper']} ${props.activeSocial === id ? cn['active'] : ''}
                             ${isSocialNetworkDisabled(id) ? cn['disabled'] : ''}`}
                                >
                                    <Icon />
                                </Flex>
                            </CustomTooltip>
                        ))}
                    </Flex>
                </Flex>
                {props.fullSearch?.trim() && !props.isLoading && (
                    <ParseItem
                        onClick={(name) => props.onParsing(name, props.activeSocial)}
                        fullSearch={props.fullSearch}
                    />
                )}

                {(props.items.length > 0 || props.error) &&
                    props.items.map((item) => <SearchItem key={item.external_id} place={props.place} {...item} />)}

                {props.isLoading && (
                    <div className={cn.loader}>
                        <LoadCircle />
                    </div>
                )}
            </div>
        </Popper>
    )
}

export default memo(Popup)
