import React, { FC } from 'react'
import cn from './tabs.module.scss'

interface Tab {
    title: string | React.ReactNode
    disabled?: boolean
    id?: string
}

interface TabsProps {
    tabs: Tab[]
    activeTab: number
    onTabClick: (index: number) => void
}

const Tabs: FC<TabsProps> = ({ tabs, activeTab, onTabClick }) => (
    <div className={cn['tabs']}>
        {tabs.map((tab, index) => (
            <div className={cn['tab-wrap']}>
                <button
                    key={tab.id}
                    className={`${cn['tab']} ${activeTab === index ? cn['active'] : ''}`}
                    onClick={() => onTabClick(index)}
                    disabled={tab.disabled}
                >
                    {tab.title}
                </button>
                {tab.disabled && <span className={cn['soon']}>soon</span>}
            </div>
        ))}
    </div>
)

export default Tabs
