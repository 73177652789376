import React, { FC } from 'react'
import cn from '@/pages/Competitor/competitor.module.scss'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { useHistory } from 'react-router-dom'
import { ReactComponent as AdsExplorer } from '@/pages/Competitor/icons/ads.svg'
import Button from '@/components/Button'
import { useTranslation } from 'react-i18next'
interface Props {
    textBtn?: string
    callback?: () => void
}

const Index: FC<Props> = ({ textBtn, callback }) => {
    const history = useHistory()
    const { t } = useTranslation()

    return (
        <Flex align="center" content="space-between" margin="0 0 24px 0">
            <Flex align="end">
                <AdsExplorer className={cn['icon']} />
                <Flex margin="0 0 0 20px" column>
                    <Text fSize="30px" semibold color="gray-new-1">
                        {t('subscription_confirmation.competitor_analysis')}
                    </Text>
                    <Flex align="center">
                        <Text
                            onClick={() => history.goBack()}
                            style={{ cursor: 'pointer' }}
                            fSize="12px"
                            semibold
                            color="gray-new-3"
                        >
                            {t('competitor_analysis.description')}
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
            <Button className={cn['not-allowed']} onClick={callback} variant="primary">
                {textBtn || t('competitor_analysis.add_monitoring') + ' +'}
            </Button>
        </Flex>
    )
}

export default Index
