import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
// import { useTranslation } from 'react-i18next'

import { RootStore } from '@/_helpers/StoreType'
import useWindowWidth from '@/_helpers/Resize/useWindowWidth'
import { Flex } from '@/_yc'
import MultiBarChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/MultiBarChart'
import DonutChart from '@/Layouts/ReportLayout/Content/ResuableWidgets/DonutChart'
import {
    // preprocessCountryNLang,
    preprocessAgeGender,
    // getCountriesFromRaw,
    // getLanguagesFromRaw,
} from '@/Layouts/ReportLayout/Content/Demographics/PreproccessData'
import { IDemographicsItem } from '@/Layouts/ReportLayout/Content/ResuableWidgets/MultiBarChart/types'
import { MockInContainer } from '../Mock/Mock'

import useSpaceTranslation from '@/_helpers/useSpaceTranslation'

const style = {
    borderRadius: '10px',
    backgroundColor: 'white',
}

const Demographics = () => {
    const t = useSpaceTranslation('report_layout.demographics')
    // const { i18n } = useTranslation()
    const ageGenderTotal = useSelector((store: RootStore) => {
        const getChannelData = store.report.rawVkData?.getVkReport?.data
        if (getChannelData?.vk_account && getChannelData?.vk_account.length > 0) {
            return getChannelData?.vk_account[0].vk_audience_agender_total
        } else return undefined
    })
    const ageGenderDist = useSelector((store: RootStore) => {
        const getChannelData = store.report.rawVkData?.getVkReport?.data
        if (getChannelData?.vk_account && getChannelData?.vk_account.length > 0) {
            return getChannelData?.vk_account[0].vk_audience_agender
        } else return undefined
    })
    const audienceCountries = useSelector((store: RootStore): Array<IDemographicsItem> => {
        if (
            !store.report.rawVkData?.getVkReport?.data.vk_account ||
            store.report.rawVkData?.getVkReport?.data.vk_account.length === 0
        ) {
            return []
        }
        const tempCountries = [...store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_audience_countries]
        return (
            tempCountries
                ?.sort((a, b) => {
                    return a.share > b.share ? -1 : 1
                })
                .map((item) => {
                    return { label: item.country, items: [{ qty: item.qty, share: item.share, color: '#6071FF' }] }
                })
                .sort() || []
        )
    })
    const audienceCities = useSelector((store: RootStore): Array<IDemographicsItem> => {
        if (
            !store.report.rawVkData?.getVkReport?.data.vk_account ||
            store.report.rawVkData?.getVkReport?.data.vk_account.length === 0
        ) {
            return []
        }
        const tempCountries = [...store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_audience_cities]
        return (
            tempCountries
                ?.sort((a, b) => {
                    return a.share > b.share ? -1 : 1
                })
                .map((item) => {
                    return { label: item.city, items: [{ qty: item.qty, share: item.share, color: '#6071FF' }] }
                }) || []
        )
    })
    const audienceUniversities = useSelector((store: RootStore): Array<IDemographicsItem> => {
        if (
            !store.report.rawVkData?.getVkReport?.data.vk_account ||
            store.report.rawVkData?.getVkReport?.data.vk_account.length === 0
        ) {
            return []
        }
        const tempCountries = [...store.report.rawVkData?.getVkReport?.data.vk_account[0].vk_audience_universities]
        return (
            tempCountries
                ?.sort((a, b) => {
                    return a.share > b.share ? -1 : 1
                })
                .map((item) => {
                    return { label: item.university, items: [{ qty: item.qty, share: item.share, color: '#6071FF' }] }
                }) || []
        )
    })
    // const audienceLang = useSelector((store: RootStore) => {
    //     return store.report.rawChannelData.getChannel?.data?.youtube_channel[0].audience_languages
    // })

    // const countries = useMemo(
    //     () => preprocessCountryNLang(getCountriesFromRaw(audienceCountries), i18n.language.slice(0, 2)),
    //     [audienceCountries, i18n.language]
    // )
    // const languages = useMemo(
    //     () => preprocessCountryNLang(getLanguagesFromRaw(audienceLang), i18n.language.slice(0, 2)),
    //     [audienceLang, i18n.language]
    // )
    const ageGender = useMemo(() => preprocessAgeGender(ageGenderDist), [ageGenderDist])

    const age_gender_Legend = useMemo(() => {
        return [
            {
                id: 2,
                value: t('age_gender.legend.men'),
                color: '#6071FF',
            },
            {
                id: 1,
                value: t('age_gender.legend.women'),
                color: '#FF659C',
            },
        ]
    }, [t])

    const genderDist = useMemo(
        () => [
            {
                label: t('gender_dist.legend.men'),
                label_color: `#6071FF`,
                value: ageGenderTotal?.total_man_share || 0,
                color: '#6071FF',
                percent_color: `#E2E6FF`,
            },
            {
                label: t('gender_dist.legend.women'),
                label_color: `#FF659C`,
                value: ageGenderTotal?.total_woman_share || 0,
                color: '#FF659C',
                percent_color: `#FFDCE2`,
            },
        ],
        [t, ageGenderTotal]
    )

    const isAgeGenderAvailable = ageGenderTotal && ageGenderTotal.total_man_share && ageGenderTotal.total_woman_share
    // genderDist[0].value + genderDist[1].value !== 0 && ageGenderCheck !== 0 && ageGender.length !== 0

    const size = useWindowWidth('mobile')
    const isMobile = size === 'mobile'

    const isCountriesAvailable = audienceCountries.length > 0
    const isCitiesAvailable = audienceCities.length > 0
    const isUniversitiesAvailable = audienceUniversities.length > 0

    if (!isAgeGenderAvailable && !isCountriesAvailable && !isCitiesAvailable && !isUniversitiesAvailable) {
        return <MockInContainer />
    }

    return (
        <>
            {isAgeGenderAvailable && (
                <Flex content="space-between" margin="20px 0 0 0" wrap>
                    <MultiBarChart
                        title={t('age_gender.title')}
                        data={ageGender}
                        legend={age_gender_Legend}
                        size="large"
                        containerStyle={{ height: isMobile ? 'unset' : '330px', ...style }}
                    />
                    <DonutChart
                        containerStyle={{
                            // width: isMobile ? 'unset' : '23%',
                            marginTop: isMobile ? '20px' : '0',
                            height: isMobile ? 'unset' : '330px',
                            ...style,
                        }}
                        data={genderDist}
                        title={t('gender_dist.title')}
                    />
                </Flex>
            )}
            <Flex content="space-between" margin="20px 0 0 0" wrap>
                {audienceCountries && audienceCountries.length !== 0 && (
                    <MultiBarChart
                        title={t('countries.title')}
                        data={audienceCountries}
                        type="horizontal"
                        size="medium"
                        containerStyle={{ padding: '20px 24px', marginBottom: '20px', ...style }}
                    />
                )}
                {audienceCities && audienceCities.length !== 0 && (
                    <MultiBarChart
                        title={t('cities.title')}
                        data={audienceCities}
                        type="horizontal"
                        size="medium"
                        containerStyle={{ padding: '20px 24px', marginBottom: '20px', ...style }}
                    />
                )}
                {audienceUniversities && audienceUniversities.length !== 0 && (
                    <MultiBarChart
                        title={t('uni.title')}
                        data={audienceUniversities}
                        type="horizontal"
                        size="medium"
                        containerStyle={{ padding: '20px 24px', marginBottom: '20px', ...style }}
                    />
                )}
            </Flex>
        </>
    )
}

export default Demographics
