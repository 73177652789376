// components/Modal/Modal.tsx
import React, { FC, ReactNode } from 'react'
import styles from './modal.module.scss'
import { ReactComponent as Close } from '@/pages/Competitor/icons/close.svg'
import { Flex } from '@/_yc'
import Text from '@/components/Text'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    children: ReactNode
    title?: string
    subTitle?: string
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, children, title, subTitle }) => {
    if (!isOpen) return null

    return (
        <div className={styles.overlay} onClick={onClose}>
            <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
                <Flex margin={subTitle ? '0 0 10px 0' : '0 0 20px 0'} content="space-between" align="center">
                    {title && (
                        <Text component="h2" className={styles.title} color={'gray-new-1'} fSize="20px" semibold>
                            {title}
                        </Text>
                    )}
                    <Close className={styles.closeButton} onClick={onClose} />
                </Flex>
                {subTitle && (
                    <Flex margin="0 0 20px 0">
                        <Text color={'gray-new-3'} fSize="14px" style={{ lineHeight: '18px' }}>
                            {subTitle}
                        </Text>
                    </Flex>
                )}
                <div className={styles.content}>{children}</div>
            </div>
        </div>
    )
}

export default Modal
