import React, { FC, useState } from 'react'
import styles from './filter-tabs.module.scss'
import { Flex } from '@/_yc'
import { useTranslation } from 'react-i18next'
import { CategoriesType, FiltersTimeType } from '@/pages/Competitor/module/types'

interface FilterTabsProps {
    categories: CategoriesType[] // Список категорий для табов
    timeFilters: { label: string; value: FiltersTimeType }[] // Список фильтров времени
    defaultCategory?: string // Значение по умолчанию для категории
    onFilterChange?: (category: CategoriesType, timeFilter: FiltersTimeType) => void // Callback для передачи выбранных значений
    getStatsMessage: (category: CategoriesType, timeFilter: string) => string // Функция для генерации текста
}

const FilterTabs: FC<FilterTabsProps> = ({
    categories,
    timeFilters,
    defaultCategory,
    onFilterChange,
    getStatsMessage,
}) => {
    const [activeCategory, setActiveCategory] = useState(categories[0])
    const [activeTimeFilter, setActiveTimeFilter] = useState<FiltersTimeType>(timeFilters[0].value)
    const { t } = useTranslation()

    const handleCategoryClick = (categoryValue: CategoriesType) => {
        setActiveCategory(categoryValue)
        if (onFilterChange) {
            onFilterChange(categoryValue, activeTimeFilter)
        }
    }

    const handleTimeFilterClick = (filter: FiltersTimeType) => {
        setActiveTimeFilter(filter)
        if (onFilterChange) {
            onFilterChange(activeCategory, filter)
        }
    }

    const statsMessage = getStatsMessage(activeCategory, activeTimeFilter)

    return (
        <div className={styles.filterTabs}>
            <div className={styles.categories}>
                {categories.map(({ label, value }) => (
                    <button
                        key={value}
                        className={`${styles.category} ${activeCategory.value === value ? styles.active : ''}`}
                        onClick={() => handleCategoryClick({ label, value })}
                    >
                        {t(label)}
                    </button>
                ))}
            </div>

            <Flex content="space-between" align="center">
                <div className={styles.statsMessage}>{statsMessage}</div>
                <div className={styles.timeFilters}>
                    {timeFilters.map((filter) => (
                        <button
                            key={filter.label}
                            className={`${styles.timeFilter} ${activeTimeFilter === filter.value ? styles.active : ''}`}
                            onClick={() => handleTimeFilterClick(filter.value)}
                        >
                            {t(filter.label)}
                        </button>
                    ))}
                </div>
            </Flex>
        </div>
    )
}

export default FilterTabs
