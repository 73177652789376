import Axios, { AxiosRequestConfig, AxiosError } from 'axios'
import * as Sentry from '@sentry/browser'
import { store } from './store'
import { RootStore } from './StoreType'
import { CORSError } from './errors/cors.error'

export interface Config {
    url: string
    baseUrl?: string
    method?: AxiosRequestConfig['method']
    headers?: { [key: string]: string }
}

export default class Service<ResponseType> {
    private baseUrl: string = process.env.REACT_APP_API_DOMAIN || 'https://api.yolotech.ru/'
    private url: string
    private method: AxiosRequestConfig['method'] = 'GET'
    private headers: { [key: string]: string } = {}

    constructor(config: Config | string) {
        if (typeof config === 'string') {
            this.url = config
        } else {
            const { url, baseUrl, method, headers }: Config = config
            if (baseUrl) this.baseUrl = baseUrl
            this.url = url
            if (method) this.method = method
            if (headers) this.headers = headers
        }
    }

    call(config?: AxiosRequestConfig, token?: string): Promise<ResponseType> {
        const headers = config?.headers
        let rest = {}
        if (config) {
            const { headers, ...res } = config
            rest = res
        }
        return new Promise((resolve, reject) => {
            Axios.request<ResponseType>({
                baseURL: this.baseUrl,
                method: this.method,
                url: this.url,
                headers: {
                    ...this.headers,
                    Authorization: token ? `Token ${token}` : undefined,
                    ...headers,
                },
                ...rest,
            })
                .then((e) => {
                    resolve(e.data)
                })
                .catch((e: AxiosError) => {
                    const state: RootStore = store.getState()
                    if (!e.response) {
                        Sentry.captureException(
                            new CORSError(
                                {
                                    token: state.authentication.user?.token || null,
                                    email: state.authentication.user?.profile?.email || null,
                                },
                                this.url
                            )
                        )
                    }
                    reject(e)
                })
        })
    }
}
