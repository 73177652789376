import Loader from '@/components/Loader'
import Flex from '@/_yc/Flex'
import GlobalWidth from '@/_yc/GlobalWidth'
import React, { FC, useEffect, useState } from 'react'
import { useAdapter, useSearch } from '../../SearchAdapter'
import { AdsGrid } from './AdsGrid/AdsGrid'
import ListHeader from '../../SearchAdapter/adsExplorer/Head/ListHeader'
import FetchCustomLists from '@/pages/Lists/CatalogOfLists/FetchCustomLists'
import YoutubeListHeader from '@/pages/SearchPage/SearchAdapter/adsExplorer/Head/YoutubeListHeader'
import { useLocation } from 'react-router-dom'
interface SearchItems {}

export const SearchItems: FC<SearchItems> = () => {
    const Adapter = useAdapter()
    const { restart, selected } = useSearch((state) => state)
    const isYoutube = useLocation().pathname.includes('youtube')

    FetchCustomLists()

    useEffect(() => {
        restart()
    }, [restart])

    const [isList, setIsList] = useState<boolean>(true)

    return (
        <div style={{ marginTop: '430px' }}>
            <GlobalWidth fixedWidth>
                <Adapter.Head setIsList={setIsList} isList={isList} />
                {isList && (
                    <>
                        {!isYoutube && <ListHeader />}
                        {isYoutube && <YoutubeListHeader />}
                    </>
                )}
                <AdsGrid isList={isList} />

                {selected.loading && (
                    <Flex content="center" margin="20px 0">
                        <Loader />
                    </Flex>
                )}
            </GlobalWidth>
        </div>
    )
}
