import React from 'react'
import styles from './top-bloggers-table.module.scss'
import CustomCheckbox from '@/components/CustomCheckbox'
import Text from '@/components/Text'
import { Flex } from '@/_yc'
import Button from '@/components/Button'
import { ReactComponent as LinkIcon } from '@/pages/Competitor/icons/Link.svg'
import Tooltip from '@/components/Tooltip'
import { useTranslation } from 'react-i18next'
import Loader from '@/components/Loader'
import { TopBlogger } from '@/pages/Competitor/module/types'
import { formatNumberWithSpaces } from '@/pages/Competitor/module/utils'

interface ITopBloggersTable {
    loading: boolean
    data: TopBlogger[]
}

const TopBloggersTable: React.FC<ITopBloggersTable> = ({ loading, data }) => {
    const { t } = useTranslation()

    return (
        <div className={styles.tableContainer}>
            <Flex margin="0 0 20px 0">
                <Text fSize="12px" color={'gray-new-2'} semibold>
                    {t('competitor_analysis.top10BloggersWhoMentioned')}
                </Text>
            </Flex>
            <div className={styles.headerRow}>
                <Flex>
                    <CustomCheckbox />
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        #
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.blogger')}
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.approx_budget')}
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.subscribers')}
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.average_likes')}
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.average_comments')}
                    </Text>
                </Flex>
                <Flex>
                    <Text fSize="10px" color={'gray-new-3'} semibold>
                        {t('competitor_analysis.average_er')}
                    </Text>
                </Flex>
                <Flex />
            </div>

            {data.map((row, index) => (
                <Flex key={row.blogger_internal_id} className={styles.row}>
                    <Flex className={styles.cell} align={'center'}>
                        <CustomCheckbox />
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {index + 1}
                        </Text>
                    </Flex>
                    <Flex className={styles.cell} align={'center'}>
                        <img className={styles.ava} src={row.avatar} alt={row.full_name} />
                        <Flex margin="0 0 0 12px" column content={'space-between'}>
                            <Text
                                className={styles.name}
                                style={{ lineHeight: '18px' }}
                                margin="0 0 6px 0"
                                fSize="14px"
                                color={'gray-new-1'}
                            >
                                {row.full_name}
                            </Text>
                            <Text fSize="10px" color={'gray-new-3'}>
                                @{row.username}
                            </Text>
                        </Flex>
                    </Flex>

                    {/* Бюджет */}
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {row.estimated_budget}
                        </Text>
                    </Flex>

                    {/* Подписчики */}
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {formatNumberWithSpaces(row.latest_metrics.followers_count || 0)}
                        </Text>
                    </Flex>

                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {formatNumberWithSpaces(row.latest_metrics?.average_likes || 0)}
                        </Text>
                    </Flex>

                    {/* Комментарии */}
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {row.latest_metrics?.average_comments || 0}
                        </Text>
                    </Flex>

                    {/* Engagement Rate */}
                    <Flex className={styles.cell} align={'center'}>
                        <Text color={'gray-new-1'} fSize="12px">
                            {(row.latest_metrics.er * 100).toFixed(1)}%
                        </Text>
                    </Flex>

                    {/* Действия */}
                    <Flex className={styles.cell} align={'center'}>
                        <Tooltip title={t('competitor_analysis.open_profile')} placement={'top'}>
                            <a href={row.profile_url} target="_blank" rel="noopener noreferrer">
                                <Flex style={{ cursor: 'pointer' }} margin="0 12px 0 0">
                                    <LinkIcon />
                                </Flex>
                            </a>
                        </Tooltip>
                        <Button className={styles.button}>{t('competitor_analysis.mentions')}</Button>
                    </Flex>
                </Flex>
            ))}
            {loading && <Loader style={{ margin: '0 auto 0 auto' }} />}
        </div>
    )
}

export default TopBloggersTable
