import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
    children: ReactNode
}

interface State {
    hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = { hasError: false }
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Caught an error:', error, errorInfo)
    }

    render() {
        if (this.state.hasError) {
            return <h2 style={{ paddingTop: '200px', textAlign: 'center' }}>Что-то пошло не так.</h2>
        }
        return this.props.children
    }
}

export default ErrorBoundary
