import React, { FC, useState, useMemo } from 'react'
import { useLocation, useHistory, useParams } from 'react-router-dom'
import cn from './tabs.module.scss'
import { Flex } from '@/_yc'
import Icons, { TUiIconName } from '@/components/Icons'
import Text from '@/components/Text'
import { Platform } from '@/pages/Reports/types'
import { InstagramAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/instagram'
import { YoutubeAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/youtube'
import { VkAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/vk'
import { TgAdapter } from '@/pages/SearchPage/SearchAdapter/bloggers/telegram'

export const platforms = [
    {
        id: Platform.YouTube,
        label: YoutubeAdapter.config.title,
        disabled: true,
    },
    {
        id: Platform.Vk,
        label: VkAdapter.config.title,
        disabled: true,
    },
    {
        id: Platform.Instagram,
        label: InstagramAdapter.config.title,
        disabled: false,
    },
    {
        id: Platform.Tg,
        label: TgAdapter.config.title,
        disabled: true,
    },
]

const Tabs: FC = () => {
    const [platformsState] = useState(platforms)
    const { pathname } = useLocation()
    const history = useHistory()
    const { id } = useParams<{ id: string }>()

    const activePlatformId = useMemo(() => {
        const platformSlug = pathname.split('/').pop()
        return platforms.find((platform) => platform.id.toLowerCase() === platformSlug)?.id || platforms[0].id
    }, [pathname])

    const handleTabClick = (platformId: string) => {
        history.push(`/ads-new/monitoring/${id}/${platformId.toLowerCase()}`)
    }

    return (
        <div className={cn.tabs}>
            {platformsState.map((platform, i) => {
                const active = activePlatformId === platform.id
                const style = {
                    height: active ? '52px' : '48px',
                    backgroundColor: active ? 'white' : '',
                    borderRadius: active ? '10px 10px 0 0' : i === 0 ? '10px 0 0 0' : '0 10px 0 0',
                    pointerEvents: platform.disabled ? 'none' : ('auto' as 'none' | 'auto'),
                }

                return (
                    <div key={platform.id} className={cn.tab} style={style} onClick={() => handleTabClick(platform.id)}>
                        {active && i !== 0 && (
                            <div className={cn.leftCurve}>
                                <div className={cn.leftCurveInner} />
                            </div>
                        )}
                        <Flex align="center" style={{ height: 18, marginTop: active ? 19 : 15, marginBottom: 15 }}>
                            <Icons
                                name={`ie-${platform.label.toLowerCase()}${active ? '' : '--disabled'}` as TUiIconName}
                                margin="0 10px 0 0"
                            />
                            <Text semibold color={active ? 'gray-1' : 'gray-new-2'}>
                                {platform.label}
                            </Text>
                        </Flex>
                        {active && (
                            <div className={cn.rightCurve}>
                                <div className={cn.rightCurveInner} />
                            </div>
                        )}
                    </div>
                )
            })}
        </div>
    )
}

export default Tabs
