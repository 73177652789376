import { AutocompleteResult, AutocompleteResultVk, DjangoPaggination, SearchItem } from '@/Reponses'
import Service from '@/_helpers/Service'

interface ICity {
    name: string
    id: number
    country_name: string
}

interface ICountry {
    name: string
    id: number
}

type ILocation = ICountry | ICity

interface ILocations {
    countries: ICountry[]
    cities: ICity[]
}

const SearchService = new Service<DjangoPaggination<SearchItem> & { founded: number; hide: boolean }>({
    method: 'POST',
    url: '/api/search/',
})
export const searchBloggers = (
    token: string,
    sorting: string,
    conditions: any[],
    search?: { text: string; by: string[] },
    page: number = 1
) =>
    SearchService.call(
        {
            data: {
                page,
                sorting,
                search,
                conditions,
            },
        },
        token
    )

const DictsService = new Service<DjangoPaggination<{ label: string; value: string }>>('/api/dicts/categories/')

export const getCategories = (token: string, name: string, locale: string) => {
    const res = DictsService.call(
        {
            url: `/api/dicts/${name}/`,
            headers: {
                'Accept-Language': locale,
            },
        },
        token
    ).then((e) => e.results)
    return res
}
export const getCategoriesChildAge = (token: string, name: string, locale: string) => {
    const res = DictsService.call(
        {
            url: `/api/dicts/${name}/`,
            headers: {
                'Accept-Language': locale,
            },
        },
        token
    ).then((e) =>
        e.results
            .filter((item) => item.value === 'baby' || item.value === 'child')
            .map((item) => {
                if (item.value === 'baby') {
                    return {
                        ...item,
                        label: '0 - 2',
                    }
                }
                return {
                    ...item,
                    label: '3 - 10',
                }
            })
    )
    return res
}

const Locations = new Service<ILocations>('/api/dicts/categories/')

export const getLocations = (token: string, locale: string, query = '') => {
    const res = Locations.call(
        {
            url: `/api/dicts/locations_v2/?term=${query}&relevant=true`,
            headers: {
                'accept-language': locale,
            },
        },
        token
    ).then((e) => {
        const cities = e.cities.map((city) => ({
            label: `${city.name}, ${city.country_name}`,
            value: { type: 'city', id: city.id },
        }))
        const countries = e.countries.map((country) => ({
            label: `${country.name}`,
            value: { type: 'country', id: country.id },
        }))
        return [...countries, ...cities]
    })

    return res
}
export const getLocationsVk = (token: string, locale: string, query = '') => {
    const res = Locations.call(
        {
            url: `/api/dicts/locations_v2/?term=${query}&relevant=true`,
            headers: {
                'accept-language': locale,
            },
        },
        token
    ).then((e) => {
        const cities = e.cities.map((city) => ({
            label: `${city.name}, ${city.country_name}`,
            value: city.name,
        }))
        const countries = e.countries.map((country) => ({
            label: `${country.name}`,
            value: country.name,
        }))
        return [...countries, ...cities]
    })

    return res
}

const AvaliableService = new Service<{ is_youtube_available: boolean }>('/api/subscriptions/current/')

export const isYTavaliable = (token: string) => {
    const res = AvaliableService.call({}, token).then((e) => e.is_youtube_available)
    return res
}

const AutocompleteService = new Service<AutocompleteResult[]>('/api/autocomplete/bloggers/')

export const searchBloggerByUsername = (token: string, search: string) =>
    AutocompleteService.call(
        {
            params: { query: search.toLowerCase() },
        },
        token
    )

const InstagramService = new Service<AutocompleteResult[]>('/api/find/instagram/blogger/')
const VKService = new Service<AutocompleteResultVk[]>('/api/find/vk/blogger/')
const TikTokService = new Service<AutocompleteResult[]>('/api/find/tiktok/blogger/')
const TwitchService = new Service<AutocompleteResult[]>('/api/find/twitch/channel/')
const YouTubeService = new Service<AutocompleteResult[]>('/api/find/youtube/channel/')
const TelegramService = new Service<AutocompleteResult[]>('/api/find/telegram/channel/')

export const searchInstagramBlogger = (token: string, search: string) =>
    InstagramService.call(
        {
            params: { username: search },
        },
        token
    )

export const searchVkBlogger = (token: string, search: string) =>
    VKService.call(
        {
            params: { username: search },
        },
        token
    )

export const searchTikTokBlogger = (token: string, search: string) =>
    TikTokService.call(
        {
            params: { username: search },
        },
        token
    )

export const searchTwitchChannel = (token: string, search: string) =>
    TwitchService.call(
        {
            params: { channel_name: search },
        },
        token
    )

export const searchYouTubeChannel = (token: string, search: string) =>
    YouTubeService.call(
        {
            params: { channel_name: search },
        },
        token
    )

export const searchTelegramChannel = (token: string, search: string) =>
    TelegramService.call(
        {
            params: { channel_name: search },
        },
        token
    )
