import React, { useEffect, useState } from 'react'
import styles from './add-search-modal.module.scss'
import Modal from '@/pages/Competitor/components/Modal'
import Button from '@/components/Button'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as Close } from '@/pages/Competitor/icons/close.svg'
import { useTranslation } from 'react-i18next'
import Loader from '@/components/Loader'

interface Brand {
    id: number
    name: string
}

interface AddSearchModalProps {
    isOpen: boolean
    onClose: () => void
    onSearch: (brands: Brand[]) => void
    title: string
    subTitle?: string
    brands: Brand[]
    loading?: boolean
}

const AddSearchModal: React.FC<AddSearchModalProps> = ({
    isOpen,
    onClose,
    onSearch,
    title,
    subTitle,
    brands,
    loading,
}) => {
    const [textareaInput, setTextareaInput] = useState('')
    const [localBrands, setLocalBrands] = useState<Brand[]>(brands)
    const { t } = useTranslation()

    useEffect(() => {
        setLocalBrands(brands)
    }, [brands])

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaInput(e.target.value)
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault()
        const pastedText = e.clipboardData.getData('Text')
        addMultipleBrandsSplit(pastedText)
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            addMultipleBrands(textareaInput)
            setTextareaInput('')
        }
    }

    const addMultipleBrands = (input: string) => {
        const newBrands = [input.trim()]
            .filter((name) => name && !localBrands.some((brand) => brand.name === name))
            .map((name) => ({ id: Date.now() + Math.random(), name }))

        setLocalBrands((prevBrands) => [...prevBrands, ...newBrands])
    }

    const addMultipleBrandsSplit = (input: string) => {
        const newBrands = input
            .split(' ')
            .filter((name) => name && !localBrands.some((brand) => brand.name === name))
            .map((name) => ({ id: Date.now() + Math.random(), name }))

        setLocalBrands((prevBrands) => [...prevBrands, ...newBrands])
    }

    const handleRemoveBrand = (id: number) => {
        setLocalBrands(localBrands.filter((brand) => brand.id !== id))
    }

    const handleSearch = () => {
        onSearch(localBrands)
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={title} subTitle={subTitle}>
            <div className={styles.container}>
                <Flex margin="0 0 6px 0" content="space-between" align="center">
                    <Text fSize="12px" semibold color={'gray-new-1'}>
                        {t('competitor_analysis.words_and_phrases')}:
                    </Text>
                    <Text fSize="12px" semibold color={'gray-new-3'}>
                        {localBrands.length} / 100
                    </Text>
                </Flex>
                <div className={styles.brandInputContainer}>
                    <div className={styles.tagInput}>
                        <Flex className={styles.brandWrapper}>
                            {localBrands.map((brand) => (
                                <Button key={brand.id} variant="primary" className={styles.btnTag}>
                                    {brand.name}
                                    <Close onClick={() => handleRemoveBrand(brand.id)} />
                                </Button>
                            ))}
                            <textarea
                                value={textareaInput}
                                onChange={handleTextareaChange}
                                onKeyUp={handleKeyUp}
                                onPaste={handlePaste}
                                placeholder={t('competitor_analysis.add_brands')}
                                className={styles.textInput}
                            />
                        </Flex>
                    </div>
                </div>
                <Flex>
                    <Button
                        disabled={!localBrands.length && !brands.length}
                        className={styles.searchBtn}
                        variant="primary"
                        onClick={handleSearch}
                    >
                        {t('competitor_analysis.save')}
                        {loading && (
                            <Loader
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                }}
                            />
                        )}
                    </Button>
                </Flex>
            </div>
        </Modal>
    )
}

export default AddSearchModal
