import React, { FC } from 'react'
import cn from './empty-content.module.scss'
import { ReactComponent as Logo } from '@/pages/Competitor/icons/ads_big.svg'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import Button from '@/components/Button'

const Index: FC<{ handleOpen: () => void }> = ({ handleOpen }) => {
    return (
        <div className={cn['root']}>
            <Flex column align="center">
                <Logo style={{ marginBottom: '24px' }} />
                <Text margin="0 0 8px 0" fSize="20px" component="h2" semibold color={'gray-new-1'}>
                    Мониторинг брендов
                </Text>
                <Text margin="0 0 20px 0" fSize="14px" semibold color={'gray-new-3'}>
                    Добавьте список ключевых слов, ИНН компаний и хештегов чтобы начать отслеживать рекламные посты
                </Text>
                <Button onClick={handleOpen} variant="primary">
                    Добавить мониторинг +
                </Button>
            </Flex>
        </div>
    )
}

export default Index
