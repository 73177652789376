import { gql } from '@apollo/client'

export interface IMentionsData {
    username: string
    title: string
    mentions_percentage: number
    er_views: number
    er_followers: number
    mentions_count: number
}
export interface IMentionsResponse {
    get_telegram_in_mentions: IMentionsData[]
    get_telegram_out_mentions: IMentionsData[]
}

export const GET_TG_REPORT_MENTIONS_IN = gql`
    query getTgReportMentions($id: String!) {
        get_telegram_in_mentions(args: { input_channel_id: $id }, limit: 5, order_by: { mentions_count: desc }) {
            er_views
            mentions_count
            mentions_percentage
            title
            username
        }
    }
`
export const GET_TG_REPORT_MENTIONS_OUT = gql`
    query getTgReportMentions($id: String!) {
        get_telegram_out_mentions(
            args: { input_channel_id: $id }
            order_by: { mentions_count: desc }
            limit: 5
            where: { title: { _neq: "null" } }
        ) {
            er_views
            mentions_count
            mentions_percentage
            title
            username
        }
    }
`
