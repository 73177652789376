import React, { useState } from 'react'
import styles from './new-search-modal.module.scss'
import Modal from '@/pages/Competitor/components/Modal'
import Button from '@/components/Button'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { ReactComponent as Info } from '@/pages/Competitor/icons/info.svg'
import { ReactComponent as Close } from '@/pages/Competitor/icons/close.svg'
import { useTranslation } from 'react-i18next'

interface Brand {
    id: number
    name: string
}

interface NewSearchModalProps {
    isOpen: boolean
    onClose: () => void
    onSearch: (reportName: string, brands: Brand[]) => void
}

const NewSearchModal: React.FC<NewSearchModalProps> = ({ isOpen, onClose, onSearch }) => {
    const [reportName, setReportName] = useState('')
    const [textareaInput, setTextareaInput] = useState('')
    const [brands, setBrands] = useState<Brand[]>([])
    const { t } = useTranslation()

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setTextareaInput(e.target.value)
    }

    const handlePaste = (e: React.ClipboardEvent<HTMLTextAreaElement>) => {
        e.preventDefault()
        const pastedText = e.clipboardData.getData('Text')
        addMultipleBrands(pastedText)
    }

    const handleKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key === 'Enter') {
            addMultipleBrands(textareaInput)
            setTextareaInput('')
        }
    }

    const addMultipleBrands = (input: string) => {
        const newBrands = [input.trim()]
            .filter((name) => name && !brands.some((brand) => brand.name === name))
            .map((name) => ({ id: Date.now() + Math.random(), name }))

        setBrands((prevBrands) => [...prevBrands, ...newBrands])
    }

    const handleRemoveBrand = (id: number) => {
        setBrands(brands.filter((brand) => brand.id !== id))
    }

    const handleSearch = () => {
        onSearch(reportName, brands)
        setReportName('')
        setBrands([])
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} title={t('competitor_analysis.new_search')}>
            <div className={styles.container}>
                <Flex column margin="0 0 20px 0">
                    <Flex margin="0 0 6px 0" align="center">
                        <Text
                            component="label"
                            htmlFor="reportName"
                            className={styles.label}
                            color={'gray-new-3'}
                            fSize="12px"
                            semibold
                        >
                            {t('target_audience.report_title')}
                        </Text>
                        <Info className={styles.infoIcon} />
                    </Flex>
                    <input
                        id="reportName"
                        type="text"
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                        placeholder={t('competitor_analysis.enter_report_name')}
                        className={styles.input}
                    />
                </Flex>
                <Flex margin="0 0 6px 0" content="space-between" align="center">
                    <Text fSize="12px" semibold color={'gray-new-1'}>
                        {t('competitor_analysis.keywords_and_hashtags_list')}
                    </Text>
                    <Text fSize="12px" semibold color={'gray-new-3'}>
                        {brands.length} / 100
                    </Text>
                </Flex>
                <div className={styles.brandInputContainer}>
                    <div className={styles.tagInput}>
                        <Flex className={styles.brandWrapper}>
                            {brands.map((brand) => (
                                <Button key={brand.id} variant="primary" className={styles.btnTag}>
                                    {brand.name}
                                    <Close onClick={() => handleRemoveBrand(brand.id)} />
                                </Button>
                            ))}
                            <textarea
                                value={textareaInput}
                                onChange={handleTextareaChange}
                                onKeyUp={handleKeyUp}
                                onPaste={handlePaste}
                                placeholder={t('competitor_analysis.add_brands')}
                                className={styles.textInput}
                            />
                        </Flex>
                    </div>
                </div>
                <Flex>
                    <Button
                        disabled={!brands.length}
                        className={styles.searchBtn}
                        variant="primary"
                        onClick={handleSearch}
                    >
                        {t('competitor_analysis.search')}
                    </Button>
                </Flex>
            </div>
        </Modal>
    )
}

export default NewSearchModal
// вввв вввыыы
