import React from 'react'
import styles from './filters.module.scss'
import Button from '@/components/Button'
import { Flex } from '@/_yc'
import Text from '@/components/Text'
import { useTranslation } from 'react-i18next'

const filters = [
    {
        title: 'competitor_analysis.keywords',
        subtitle: 'competitor_analysis.expand_search',
        type: 'keywords',
    },
    {
        title: 'competitor_analysis.exclude_words',
        subtitle: 'competitor_analysis.exclude_unwanted_words',
        type: 'excludeWords',
    },
    {
        title: 'competitor_analysis.hashtags',
        subtitle: 'competitor_analysis.add_hashtags',
        type: 'hashtags',
    },
]
interface FiltersProps {
    setFilterType: (type: 'keywords' | 'excludeWords' | 'hashtags') => void
    brands: { [key in 'keywords' | 'excludeWords' | 'hashtags']: { id: number; name: string }[] }
}

const Filters: React.FC<FiltersProps> = ({ setFilterType, brands }) => {
    const { t } = useTranslation()

    const onClickButton = (type: 'keywords' | 'excludeWords' | 'hashtags') => {
        setFilterType(type)
    }

    return (
        <div className={styles.filtersContainer}>
            {filters.map((filter) => (
                <div key={filter.title} className={styles.filterBlock}>
                    <Flex column>
                        <Text semibold fSize="14px" color="gray-new-1" component="h4">
                            {t(filter.title)}
                        </Text>
                        <Text margin="0 0 16px 0" fSize="12px" color="gray-new-3">
                            {t(filter.subtitle)}
                        </Text>
                    </Flex>
                    <Flex className={styles.mentionsCount} content="space-between" align="center">
                        <span>
                            {brands[filter.type as 'keywords' | 'excludeWords' | 'hashtags'].length}{' '}
                            {filter.type === 'hashtags'
                                ? t('competitor_analysis.hashtags_count')
                                : t('competitor_analysis.words')}
                        </span>
                        <Button
                            variant="outline"
                            onClick={() => onClickButton(filter.type as 'keywords' | 'excludeWords' | 'hashtags')}
                            disabled={filter.type === 'excludeWords'}
                        >
                            {t('competitor_analysis.add')} +
                        </Button>
                    </Flex>
                </div>
            ))}
        </div>
    )
}

export default Filters
