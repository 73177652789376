import React, { useState, useEffect, useCallback } from 'react'
import Header from './components/Header'
import ServiceCard from './components/ServiceCard'
import Footer from './components/Footer'
import cn from './subscription-confirmation.module.scss'
import PageLayout from '@/Layouts/PageLayout'
import ModalSubscription from './components/ModalSubscription'
import { useTranslation } from 'react-i18next'
import { serviceCardsData } from '@/pages/SubscriptionConfirmation/const/data'
import { checkPaymentStatus } from '@/pages/SubscriptionConfirmation/sevices'
import service from '@/pages/Auth/LoginPage/Model/_service'
import useAuthCall from '@/_helpers/Hooks/useAuthCall'
import { getCurrentTariff } from '@/Services/Billing'
import ym from 'react-yandex-metrika'

const POLLING_INTERVAL = 8000
const POLLING_TIMEOUT = 60000

interface Tariff {
    remain_reports: number
    remain_intersections: number
    remain_chats: number
}

interface PaymentStatusResponse {
    status: 'succeeded' | 'canceled' | 'pending'
    is_paid: boolean
}

const SubscriptionConfirmation: React.FC = () => {
    const { t } = useTranslation()
    const [isModalOpen, setModalOpen] = useState(true)
    const [isPaymentSuccessful, setPaymentSuccessful] = useState<boolean | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [tariff, setTariff] = useState<Tariff>({ remain_reports: 0, remain_intersections: 0, remain_chats: 0 })
    const user = JSON.parse(localStorage.getItem('user') || '{"token": ""}')
    // eslint-disable-next-line
    const [loading, data, error, reload] = useAuthCall(getCurrentTariff)

    useEffect(() => {
        reload()
    }, [reload])

    useEffect(() => {
        if (data?.tariff?.package?.id === 4) {
            ym('reachGoal', 'Enterprise')
        }
        if (data?.tariff?.package?.id === 1) {
            ym('reachGoal', 'Professional')
        }
        if (data?.tariff?.package?.id === 3) {
            ym('reachGoal', 'Standard')
        }
    }, [data])

    const fetchTariff = useCallback(async () => {
        try {
            // @ts-ignore
            const fetchedTariff = await service.getSubscriptionPlatforms()
            setTariff({
                remain_reports: fetchedTariff?.remain_reports || 0,
                remain_intersections: fetchedTariff?.remain_intersections || 0,
                remain_chats: fetchedTariff?.remain_chats || 0,
            })
        } catch (error) {
            console.error('Ошибка при загрузке тарифа:', error)
        }
    }, [])

    const fetchPaymentStatus = useCallback(async (): Promise<PaymentStatusResponse | null> => {
        try {
            return await checkPaymentStatus(user?.token)
        } catch (error) {
            console.error('Ошибка при проверке статуса оплаты:', error)
            return null
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user?.token])

    const handlePaymentStatus = useCallback((response: PaymentStatusResponse | null) => {
        if (response) {
            if (response.status === 'succeeded' && response.is_paid) {
                setPaymentSuccessful(true)
                setIsLoading(false)
            } else if (response.status === 'canceled') {
                setPaymentSuccessful(false)
                setIsLoading(false)
            } else if (response.status === 'pending') {
                setPaymentSuccessful(false)
            }
        } else {
            setPaymentSuccessful(false)
        }
    }, [])

    useEffect(() => {
        let pollingIntervalId: NodeJS.Timeout
        let timeoutId: NodeJS.Timeout

        const startPolling = async () => {
            setIsLoading(true)
            await fetchTariff()

            const initialResponse = await fetchPaymentStatus()
            handlePaymentStatus(initialResponse)

            if (initialResponse?.status === 'pending') {
                pollingIntervalId = setInterval(async () => {
                    const response = await fetchPaymentStatus()
                    handlePaymentStatus(response)

                    if (response?.status !== 'pending') {
                        stopPolling()
                    }
                }, POLLING_INTERVAL)

                timeoutId = setTimeout(() => {
                    stopPolling()
                    if (isPaymentSuccessful === null) {
                        setPaymentSuccessful(false)
                    }
                }, POLLING_TIMEOUT)
            }
        }

        const stopPolling = () => {
            clearInterval(pollingIntervalId)
            clearTimeout(timeoutId)
            setIsLoading(false)
        }

        startPolling()

        return stopPolling
    }, [fetchPaymentStatus, fetchTariff, handlePaymentStatus, isPaymentSuccessful])

    const handleCloseModal = () => setModalOpen(false)

    const getModalContent = () => {
        if (isLoading) {
            return {
                title: t('subscription_confirmation.payment_pending'),
                description: t('subscription_confirmation.payment_pending_description'),
            }
        }
        return isPaymentSuccessful
            ? {
                  title: t('subscription_confirmation.payment_accepted'),
                  description: t('subscription_confirmation.payment_description'),
              }
            : {
                  title: t('subscription_confirmation.payment_failed'),
                  description: t('aud_insides.send_to_mail.approve.er'),
              }
    }

    return (
        <PageLayout title={t('subscription_confirmation.page_title')} color="common-background">
            <div className={cn['container']}>
                <Header
                    title={
                        isPaymentSuccessful
                            ? t('subscription_confirmation.title')
                            : t('subscription_confirmation.payment_pending')
                    }
                    subtitle={t('subscription_confirmation.subtitle')}
                    tariff={tariff}
                />

                <div className={cn['services']}>
                    {serviceCardsData.map((card, index) => (
                        <ServiceCard
                            key={index}
                            title={t(card.title)}
                            description={t(card.description)}
                            icon={card.icon}
                            link={card.link}
                            icons={card.icons}
                        />
                    ))}
                </div>

                <Footer text={t('subscription_confirmation.footer_text')} />

                <ModalSubscription
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isLoading={isLoading}
                    isPaymentSuccessful={isPaymentSuccessful}
                    title={getModalContent().title}
                    description={getModalContent().description}
                    buttonText={t('subscription_confirmation.continue_button')}
                    buttonDisabled={isLoading}
                    error={!isPaymentSuccessful && !isLoading}
                />
            </div>
        </PageLayout>
    )
}

export default SubscriptionConfirmation
