import { Platform } from '@/pages/Reports/types'

/**
 * @param string
 * @returns string[] of usernames
 */
export default (string) => {
    const result = new Set()

    string
        .trim()
        .split(/(\s|,)/)
        .filter((item) => !!item && item !== ' ' && item !== ',')
        .forEach((name) => {
            result.add(parseName(name))
        })

    return [Array.from(result)[0]]
}
const parseTikTokUrl = (url) => {
    const parts = url.split('/');
    return parts[parts.length - 1].split('?')[0]; // Извлекает последнюю часть URL перед параметрами
};

export const parseBloggerInput = (name, curPlatform) => {
    const inst = name.search('instagram.com');
    const yt = name.search('youtube.com');
    const twitch = name.search('twitch.tv');
    const vk = name.search('vk.com');
    const tg = name.search('t.me');
    const tiktok = name.search('tiktok.com');

    if (inst !== -1) {
        return { type: Platform.Instagram, name: parseUrl(name) };
    }
    if (yt !== -1) {
        return {
            type: !name.includes('/channel/') ? 'yt_username' : Platform.YouTube,
            name: parseYoutubeUrl(name),
        };
    }
    if (twitch !== -1) {
        return { type: Platform.Twitch, name: parseTwitchUrl(name) };
    }
    if (vk !== -1) {
        return { type: Platform.Vk, name: parseVkUrl(name) };
    }
    if (tg !== -1) {
        return { type: Platform.Tg, name: name };
    }
    if (tiktok !== -1) {
        return { type: Platform.TikTok, name: parseTikTokUrl(name) };
    }
    // only nickname (not URL) - current Platform type
    if (curPlatform && inst === -1 && yt === -1 && twitch === -1 && tiktok === -1) {
        const typeExtended = curPlatform === Platform.YouTube ? 'yt_username' : curPlatform;
        const trimName = (name.includes('@') ? name.split('@')[1] : name).split('?')[0].replaceAll('/', '');
        return { type: typeExtended, name: trimName };
    }
    return { type: Platform.Instagram, name: name.split('?')[0].replaceAll('/', '') };
};


export const parseName = (name) => {
    const inst = name.search('instagram.com')
    if (inst !== -1) {
        return parseUrl(name)
    } else {
        return name
    }
}

const parseYoutubeUrl = (url) => {
    const anchorIndex = url.search('youtube.com')

    //https://www.youtube.com/@atName  
    if (url.includes('@')) {
        return url.split('@')[1].split('/')[0]
    }

    //https://www.youtube.com/user/userName 
    if (url.includes('user')) {
        return url.split('user/')[1].split('/')[0]
    }

    //https://www.youtube.com/channel/channel_ID AND https://www.youtube.com/c/channelName
    if (url.includes('/channel/') || url.includes('/c/')) {
        const username = url.slice(anchorIndex).split('/')[2]
        if (username.search(/\?/) === -1) return username

        return username.split('?')[0]
    }

    //https://www.youtube.com/channelName1
    const username = url.slice(anchorIndex).split('/')[1]
    return username

}

const parseTwitchUrl = (url) => {
    const anchorIndex = url.search('twitch.tv')
    const username = url.slice(anchorIndex).split('/')[1]

    return username
}
const parseVkUrl = (url) => {
    const anchorIndex = url.search('vk.com')
    const username = url.slice(anchorIndex).split('/')[1]

    return username
}
//eslint-disable-next-line
const parseTgUrl = (url) => {
    const anchorIndex = url.search('t.me')
    const username = url.slice(anchorIndex).split('/')[1]

    return username
}
const parseUrl = (url) => {
    const anchorIndex = url.search('instagram.com')
    const username = url.slice(anchorIndex).split('/')[1]

    if (username.search(/\?/) === -1) return username

    return username.split('?')[0]
}

/*
    url: String
    anchorIndex: number
 
    return: String
*/

export { parseUrl }
