import React, { FC, useState, useRef, useEffect } from 'react'
import styles from './stats-card.module.scss'
import { Flex } from '@/_yc'
import Button from '@/components/Button'
import Tooltip from '@/components/Tooltip'
import { useTranslation } from 'react-i18next'

interface Stat {
    label: string
    value: string | number
    tooltip?: string
}

interface StatsCardProps {
    title: string
    subtitle?: string
    stats: Stat[]
    onActionClick?: (inn: string) => void
    inn?: string
}

const StatsCard: FC<StatsCardProps> = ({ title, subtitle, stats, onActionClick, inn }) => {
    const [showInnInput, setShowInnInput] = useState(false)
    const [text, setText] = useState('')
    const inputWrapperRef = useRef<HTMLDivElement>(null)
    const { t } = useTranslation()

    const char = title[0]

    const handleAddInnClick = () => {
        setShowInnInput(true)
    }

    const handleSaveInn = () => {
        if (text.trim() && onActionClick) {
            onActionClick(text)
            setText('')
            setShowInnInput(false)
        }
    }

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputWrapperRef.current && !inputWrapperRef.current.contains(event.target as Node)) {
                setShowInnInput(false)
                setText('')
            }
        }

        if (showInnInput) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [showInnInput])

    return (
        <Flex content="space-between" className={styles.card}>
            <div className={styles.header}>
                <div className={styles.char}>{char}</div>
                <Flex column content="center">
                    <div className={styles.title}>{title}</div>
                    <Flex align="center">
                        {subtitle && !showInnInput && <div className={styles.subtitle}>{inn ? inn : subtitle}</div>}
                        {showInnInput ? (
                            <div className={styles.innInputWrapper} ref={inputWrapperRef}>
                                <input
                                    type="text"
                                    className={styles.innInput}
                                    value={text}
                                    onChange={(e) => setText(e.target.value)}
                                    placeholder="Введите ИНН"
                                />
                                <Button variant="primary" className={styles.saveButton} onClick={handleSaveInn}>
                                    +
                                </Button>
                            </div>
                        ) : (
                            onActionClick && (
                                <button className={styles.action} onClick={handleAddInnClick}>
                                    +
                                </button>
                            )
                        )}
                    </Flex>
                </Flex>
            </div>
            <div className={styles.stats}>
                {stats.map((stat, index) => (
                    <div key={index} className={styles.stat}>
                        <div className={styles.value}>{stat.value}</div>
                        <div className={styles.label}>
                            {t(stat.label)}
                            {stat.tooltip && (
                                <Tooltip title={t(stat.tooltip)} placement={'top'}>
                                    <span className={styles.tooltip}>i</span>
                                </Tooltip>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </Flex>
    )
}

export default StatsCard
